// local credentials 
// export const material = {
//     pillowFabric: ["5f98f88a164cb394fc0b44ec"],
//     vintagePaint: ["5e9ee80be92bde00086ad274"],
// }

// export const subMaterial = {
//     pillowFabric: ["5f98f88a164cb394fc0b44f7"],
//     pillowNo: ['63e39c8a170ca20008c169af'],
//     pillow2_4Side: ["642bfa6f93c41a00081721fa"],
//     vintagePaint: ['63f495f8e2a41600080efbea', '63f4961d6185cd0008fe9577'],
//     upholsteryFabric: ['63e39c6f170ca20008c169a2']
// }

// export const fabricCompanyId = '5f991484a0d40e0e988e1722'
// export const GQL_URL = 'http://localhost:4000/atlas9'

export const TOKEN = window.location.origin === 'https://stout.rarefurnish.com' ? 'Stout@4321' : 'Lazar@4321'
// prod credentials
export const material = {
    pillowFabric: ["5d7ce0484e8d3c763cf7b4df"],
    legWood: ['5d7ce0484e8d3c763cf7b4de'],
    metalWood: ['5d7ce0484e8d3c763cf7b4dc']
}
export const checkOriginCondi = window.location.origin === 'https://stout.rarefurnish.com'

export const ImageGlobalUrl = 'https://app.poly9.ai/public'
export const subMaterial = {
    pillowFabric: ["5d7ce0484e8d3c763cf7b509"],
    legWood: ['5d7ce0484e8d3c763cf7b4fd'],
    metalWood: ['5d7ce0484e8d3c763cf7b4ee']
}

export const stoutSubMaterial = {
    pillowFabric: ["5d7ce0484e8d3c763cf7b509"],
    legWood: ['5d7ce0484e8d3c763cf7b4fd'],
    metalWood: ['5d7ce0484e8d3c763cf7b4ee']
}

export const stoutMaterial = {
    pillowFabric: ["5d7ce0484e8d3c763cf7b4df"],
    legWood: ['5d7ce0484e8d3c763cf7b4de'],
    metalWood: ['5d7ce0484e8d3c763cf7b4dc']
}

export const stoutFabricCompanyId = '6523e1c25c55ac0008276be4'
export const fabricCompanyId = '651d2f5646cb5000084cb391'
// export const GQL_URL = 'https://test.virtuosopastiche.com/test/atlas9'
// export const GQL_URL = 'http://localhost:4000/atlas9'
export const GQL_URL = 'https://apis.polyfoundry.com/prod/atlas9'
export const Handle_Global_Url = 'https://s3.ap-south-1.amazonaws.com/app.poly9.ai/public/636e083bd5c1580008c1d3fa/website-image/handle_thumb/'

export const fabricType = 'GET_FINISHES'
export const fabricKey = 'uMkvMKema7XmKXHhT'

export const seatCushionData = [
    {
        name: 'C1 T-cush/21" dp/3',
        img: 'Upholstery/Seat cushion/C1 T-cush-21.png'
    },
    {
        name: 'C2 T-cush/21" dp/2',
        img: 'Upholstery/Seat cushion/C2 T-cush-21.png'
    },
    {
        name: 'C3 T-cush/21" dp/1',
        // img: 'C2 T-cush-21.png'
        img: 'Upholstery/Seat cushion/C-1.png'
    },
    // {
    //     name: 'C4 T-cush/21" dp/3',
    //     img: 'C1 T-cush-21.png'
    // },
    // {
    //     name: 'C5 T-cush/21" dp/3',
    //     img: 'C2 T-cush-21.png'
    // },
    // {
    //     name: 'C6 T-cush/21" dp/3',
    //     img: 'C2 T-cush-21.png'
    // },
]

export const backPillowData = [
    {
        name: 'D16 BoxBorder /36h/3',
        img: 'Upholstery/Back Pillow/D16 BoxBorder-.png'
    },
    {
        name: 'D19 SingleWelt /36h/3',
        img: 'Upholstery/Back Pillow/D19 SingleWelt-.png'
    },
    {
        name: 'D20 SingleWelt /36h/3',
        img: 'Upholstery/Back Pillow/D20 SingleWelt-.png'
    },
    {
        name: 'D17 BoxBorder /36h/3',
        img: 'Upholstery/Back Pillow/D17 BoxBorder-.png'
    },
]

export const baseOrLegData = [
    {
        name: 'E21 155L leg',
        img: 'Upholstery/Leg/E21 155L leg.png'
    },
    {
        name: 'E21 4160L Leg',
        img: 'Upholstery/Leg/E21 4160L Leg.png'
    },
    {
        name: 'E21 4394L Leg',
        img: 'Upholstery/Leg/E21 4394L Leg.png'
    },
]
export const nailheadData = [
    {
        name: 'Yes',
        img: 'Group 1710.png'
    },
    {
        name: 'No',
        img: 'pillows-no.png'
    },
]
export const pillowAllSize = [
    {
        setSize: '12x20',
        image: '12x20.png',
        name: '12x20'
    },
    {
        setSize: '12x24',
        image: '12x24.png',
        name: '12x20'
    },
    {
        setSize: '14x20',
        image: '14x20.png',
        name: '12x20'
    },
    {
        setSize: '14x24',
        image: '14x24.png',
        name: '12x20'
    },
    {
        setSize: '18x18',
        image: '18x18.png',
        name: '20x20'
    },
    {
        setSize: '20x20',
        image: '20.png',
        name: '20x20'
    },
    {
        setSize: '22x22',
        image: '22x22.png',
        name: '20x20'
    },
    {
        setSize: '24x24',
        image: '24x24.png',
        name: '20x20'
    },

]
export const insertType = [
    {
        name: 'Pillow Cover Only',
        image: 'Pillow Cover Only.png'
    },
    {
        name: 'Down',
        image: 'Down.png'
    },
    {
        name: 'Faux Down',
        image: 'Faux Down.png'
    }
]
export const yardValue = {
    '12x20': {
        'yard': 0.5,
        'Vertical': 1,
        'Inset Band': 2,
        'Center': 0.5,
        'Flange 1"': 0.5,
        'Flange 2"': 0.5,
        '4 Side': 2,
        '2 Side': 1,
        'Welt': 0.5,
    },
    '20x20': {
        'yard': 0.75,
        'Vertical': 1.5,
        'Inset Band': 2,
        'Center': 0.75,
        'Flange 1"': 0.5,
        'Flange 2"': 0.5,
        '4 Side': 2.5,
        '2 Side': 1.5,
        'Welt': 0.5,
    },
}




export const upholsterySidebar = [
    {
        key: 'TAB_1',
        img: 'Fabric.svg',
        name: 'Fabrics',
        parts: ['Fabric'] // change here
    },
    {
        key: 'TAB_2',
        img: 'Upholstery/leg.svg',
        name: 'Legs',
        parts: ['Legs']
    },
    {
        key: 'TAB_3',
        img: 'SEAT.svg',
        name: 'Seat Cushions',
        parts: ['Fabric']
    },
    {
        key: 'TAB_4',
        img: 'fabric.png',
        name: 'Pillow',
        parts: ['Pillow']
    },
    // {
    //     key: 'TAB_3',
    //     img: 'SEAT.svg',
    //     name: 'Contrasting Fabric',
    //     parts: ['Fabric']
    // },
    // {
    //     key: 'TAB_4',
    //     img: 'SEAT.svg',
    //     name: 'Cushion Fill',
    //     parts: ['Fabric']
    // },
    // {
    //     key: 'TAB_5',
    //     img: 'SEAT.svg',
    //     name: 'Feather Float™ - 50/50 Blend',
    //     parts: ['Fabric']
    // },
    // {
    //     key: 'TAB_6',
    //     img: 'SEAT.svg',
    //     name: 'Trillium',
    //     parts: ['Fabric']
    // },
    // {
    //     key: 'TAB_7',
    //     img: 'SEAT.svg',
    //     name: 'Memory Foam',
    //     parts: ['Fabric']
    // },
    // {
    //     key: 'TAB_8',
    //     img: 'SEAT.svg',
    //     name: 'Casters',
    //     parts: ['Fabric']
    // },

    // {
    //     key: 'TAB_9',
    //     img: 'SEAT.svg',
    //     name: 'Nailhead',
    //     parts: ["Base", "Seat", "Backrest", "Fabric", 'Arm_Style', 'Seat_Cushion', 'Back_Pillow', 'Sofa']
    // },
    // {
    //     key: 'TAB_10',
    //     img: 'SEAT.svg',
    //     name: 'Metal swivel base option',
    //     parts: ["Base", "Seat", "Backrest", "Fabric", 'Arm_Style', 'Seat_Cushion', 'Back_Pillow', 'Sofa']
    // },

]

export const PLATINUM_Seat_Cushions = {
    name: "Seat Cushions",
    description: "Rich down fiber and feather pillows resting on our new 2.3 HR poly/down seat cushions",
    options: [
        {
            optionCode: "OPT20-2",
            description: "Premium Cushion Seat Chair/Wedge",
            img: "options/platinum_seat_cushions/OPT20-2.png",
            cost: "$300",
        },
        {
            optionCode: "OPT20-3",
            description: "Premium Cushion seat Chaise",
            img: "options/platinum_seat_cushions/OPT20-3.png",
            cost: "$600",
        },
        {
            optionCode: "OPT20-4",
            description: "Premium Cushion Seat Sofa",
            img: "options/platinum_seat_cushions/OPT20-4.png",
            cost: "$900",
        },
    ],
};

export const Contrasting_Fabric = {
    name: "Contrasting Fabric",
    description: "",
    options: [
        {
            optionCode: "OPT 13BU-1",
            description: "Contrast Buttons - Ultra/Leather",
            img: "options/contrasting_fabric/OPT 13BU-1.png",
            cost: "$105/product",
        },
        {
            optionCode: "OPT 10-2",
            description: "Contrast Welt - Ultra/Leather",
            img: "options/contrasting_fabric/OPT 10-2.png",
            cost: "$324/product",
        },
        {
            optionCode: "OPT 13A-1",
            description: "Contrast Toss Pillow  GRD 23 >/Leather",
            img: "options/contrasting_fabric/OPT 13A-1.png",
            cost: "$225.00/$300.00/Pillow",
        },
        {
            optionCode: "OPT 13B",
            description: "Contrast Inside/Outside Back",
            img: "options/contrasting_fabric/OPT 13B.png",
            cost: "Average*",
        },
        {
            optionCode: "OPT 13SB",
            description: "Contrast Seat",
            img: "options/contrasting_fabric/OPT 13SB.png",
            cost: "Average*",
        },
    ],
};

export const Cushion_Fill = {
    name: "Cushion Fill",
    description: "Standard fill is 1.8lb Flexible Polyurethane HR foam",
    options: [
        {
            optionCode: "OPTBC",
            description: "Bench Cushion Option",
            img: "options/cushion_fill/OPTBC.png",
            cost: "$405",
        },
        {
            optionCode: "OPT37-1",
            description: "100% Feather Fill Single Toss Pillow",
            img: "options/cushion_fill/OPT37-1.png",
            cost: "$96",
        },
    ],
};

export const Feather_Float_Blend = {
    name: "Feather Float™ - 50/50 Blend",
    description:
        "Available in loose backs, seats or toss pillows. Fill blend is 50% (15/85 down/feather) and 50% (poly fiber).",
    options: [{
        optionCode: "OPT 28-1",
        description: "Single Toss Pillow",
        img: "options/feather_float_blend/OPT 28-1.png",
        cost: "$90",
    },
    {
        optionCode: "OPT 28-2",
        description: "Backs - Chair, Wedge & Chaise",
        img: "options/feather_float_blend/OPT 28-2.png",
        cost: "$225",
    },
    {
        optionCode: "OPT 28-3",
        description: "Backs - Loveseat & Sofa",
        img: "options/feather_float_blend/OPT 28-3.png",
        cost: "$300",
    },
    {
        optionCode: "OPT 29-2",
        description: "Seats - Chair & Wedge",
        img: "options/feather_float_blend/OPT 29-2.png",
        cost: "$180",
    },
    {
        optionCode: "OPT 29-3",
        description: "Seats - Chaise, Loveseat & Sofa",
        img: "options/feather_float_blend/OPT 29-3.png",
        cost: "$240",
    },
    ],
};

export const Trillium = {
    name: "Trillium",
    description: "Available in loose backs, seats or toss pillows",
    options: [
        {
            optionCode: "OPT 30-1",
            description: "Single Toss Pillow",
            img: "options/trillium/OPT 30-1.png",
            cost: "$90",
        },
        {
            optionCode: "OPT 30-2-1",
            description: "Backs- Chair, Wedge & Chaise",
            img: "options/trillium/OPT 30-2.png",
            cost: "$225",
        },
        {
            optionCode: "OPT 30-3",
            description: "Backs - Loveseat & Sofa",
            img: "options/trillium/OPT 30-3.png",
            cost: "$300",
        },
        {
            optionCode: "OPT 30-2-2",
            description: "Seats-chair&Wedge",
            img: "options/trillium/OPT 30-2.png",
            cost: "$300",
        },
        {
            optionCode: "OPT 31-3",
            description: "Seats Chaise & Loveseat",
            img: "options/trillium/OPT 31-3.png",
            cost: "$600",
        },
        {
            optionCode: "OPT 31-4",
            description: "Seats  Sofa",
            img: "options/trillium/OPT 31-4.png",
            cost: "$900",
        },
    ],
};

export const Memory_Foam = {
    name: "Memory Foam",
    description: "",
    options: [
        {
            optionCode: "OPT 42-1",
            description: "Seats - Ottoman, Chair, Wedge",
            img: "options/memory_foam/OPT 42-1.png",
            cost: "$339",
        },
        {
            optionCode: "OPT 42-2",
            description: "Chaise, Loveseat & Sofa",
            img: "options/memory_foam/OPT 42-2.png",
            cost: "$486",
        },
    ],
};

export const Casters = {
    name: "Casters",
    description: "",
    options: [
        {
            optionCode: "OPT 3",
            description: "Set of 4 Black Hooded Twin Wheel Casters",
            img: "options/casters/OPT 3.png",
            cost: "$96",
        },
        {
            optionCode: "OPT 4",
            description: "Set of 4 Black Hooded Ball Caster w/Top Plastic",
            img: "options/casters/OPT 4.png",
            cost: "$96",
        },
        {
            optionCode: "OPT 5",
            description: "Set of 4 Brass Hooded Hard Plastic Ball Casters",
            img: "options/casters/OPT 5.png",
            cost: "$96",
        },
        {
            optionCode: "OPT 6",
            description: "Set of 4 Antique Brass Hooded Hard Plastic Ball Casters",
            img: "options/casters/OPT 6.png",
            cost: "$96",
        },
        {
            optionCode: "OPT 7",
            description: "Set of 4 Chrome Plated Hooded Hard Plastic Ball Casters",
            img: "options/casters/OPT 7.png",
            cost: "$96",
        },
        {
            optionCode: "OPT 8",
            description: "Set of 4 Dual Wheel/Hooded w/Non-marking Urethane Wheels",
            img: "options/casters/OPT 8.png",
            cost: "$162",
        },
    ],
};

export const Nailhead_Finishes = {
    name: "Nailhead ",
    description: "",
    options: [
        {
            optionCode: "B - Black",
            description: "B - Black",
            img: "options/nailhead_finishes/Black.png",
            cost: "",
        },
        {
            optionCode: "K - Polished Nickel",
            description: "K - Polished Nickel",
            img: "options/nailhead_finishes/Polished Nickel.png",
            cost: "",
        },
        {
            optionCode: "N- Natural",
            description: "N- Natural",
            img: "options/nailhead_finishes/Natural.png",
            cost: "",
        },
        {
            optionCode: "P - Platinum",
            description: "P - Platinum",
            img: "options/nailhead_finishes/Platinum.png",
            cost: "",
        },
        {
            optionCode: "W - Midas",
            description: "W - Midas",
            img: "options/nailhead_finishes/Midas.png",
            cost: "",
        },
        {
            optionCode: "Z - Distressed",
            description: "Z - Distressed",
            img: "options/nailhead_finishes/Distressed.png",
            cost: "",
        },
    ],
};

export const Metal_Swivel_Base_Options = {
    name: "Metal swivel base option",
    description: "",
    options: [{
        optionCode: "OPT-MTL-PSS",
        description: "Swivel Base Polished Stainless Steel",
        img: "options/metal_swivel_base_options/OPT-MTL-PSS.png",
        cost: "$0",
        "product code": "stocked",
    },
    {
        optionCode: "OPT-MTL-HAB",
        description: "Swivel Base Antique Brass",
        img: "options/metal_swivel_base_options/OPT-MTL-HAB.png",
        cost: "$200",
        "product code": "stocked",
    },
    {
        optionCode: "OPT-MTL-SB",
        description: "Swivel Base Satin Brass",
        img: "options/metal_swivel_base_options/OPT-MTL-SB.png",
        cost: "$200",
        "product code": "stocked",
    },
    {
        optionCode: "OPT-MTL-ORB",
        description: "Swivel Base Oil Rub Bronze",
        img: "options/metal_swivel_base_options/OPT-MTL-ORB.png",
        cost: "$200",
        "product code": "Custom Order/16-20 wks",
    },
    ],
};
