import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import MyDropzone from "../../../components/DropZone";
import Loader from "../../../components/Loader";
import { useRecoilState } from "recoil";
import { upholsteryAtom, modelAtom, selectedFabricAtom, tabAtom, currentCustomizationAtom, uploadFabricAtom, showModalAtom, beforeSelectedImageScaletom, aspectRationAtom, cropperSelectionAtom, addNewCustomFabricAtom, uploadImageAtom, selectedImageScaletom, selectedCustomFabricAtom, legSelectionAtom } from "../../../state";
import { GET_URL } from "../../../constans";
import { GET_FABRICS } from "../graphQl/Query";
import { useLazyQuery } from "@apollo/client";
import { checkOriginCondi, fabricCompanyId, fabricKey, fabricType, stoutFabricCompanyId } from "../../../lib/constant";
import { DebounceInput } from "react-debounce-input";

function FabricBox({ item, setSelectedFabric }: { item: any, setSelectedFabric: Function }) {
    const [upholsteryAtomState, setUpholsteryAtomState] = useRecoilState<{ name: string; partWiseFabric: any, currentPart: string[] }>(upholsteryAtom);
    const getCheckSupport = localStorage.getItem('checkSupport')
    const base64Image = item.finish?.search('data:image') !== -1 ? true : false
    const [inputSetValue, setInputSetValue] = useRecoilState<any>(beforeSelectedImageScaletom);
    const [uploadImage, setUploadImage] = useRecoilState(uploadImageAtom)
    const selectedFabric = upholsteryAtomState?.partWiseFabric?.[upholsteryAtomState?.currentPart[0]] || {};
    const [inputValue, setInputValue] = useRecoilState(selectedImageScaletom);
    const [aspectRation, setAspectRation] = useRecoilState(aspectRationAtom)
    const [cropperData, setCropperData] = useRecoilState(cropperSelectionAtom)
    const [selectedCustomFabric, setSelectedCustomFabricAtom] = useRecoilState(selectedCustomFabricAtom)
    const [legSelection, setLegSelection] = useRecoilState(legSelectionAtom)
    return (
        <div key={item._id} className={`fabric-box item-choose me-3 mb-3 
        ${base64Image ? inputSetValue?.id === item.id ? 'active' : '' : item._id === selectedFabric?._id ? "active" : ""}
        cursor-pointer`}
            onClick={() => {
                if (base64Image) {
                    setUploadImage(item.finish)
                    setInputValue({
                        "Horizontal": item?.setHorizontal || 0,
                        "Vertical": item?.setVertical || 0,
                        "Scale": item?.Scale || 1
                    })
                    setAspectRation(item?.aspectRation || {})
                    setCropperData(item?.cropperData || {})
                    setSelectedCustomFabricAtom(item)
                    setInputSetValue(item)
                } else {
                    setInputSetValue({})
                    setUploadImage('')
                }
                setSelectedFabric(item)
                const partWiseFabric = upholsteryAtomState.currentPart.reduce((acc: any, part: string) => {
                    acc[part] = { ...item, legSelection }
                    return acc
                }, {})
                const newFabric = { ...upholsteryAtomState, partWiseFabric: { ...upholsteryAtomState.partWiseFabric, ...partWiseFabric } };
                setUpholsteryAtomState({
                    ...newFabric,
                })
            }}
        >
            <img src={base64Image ? item.finish : GET_URL(getCheckSupport ? item.finish?.replaceAll('.jpg', '.webp') : item.finish)} className="img-fluid" />
            {/* <img src={GET_URL(getCheckSupport ? item.finish?.replaceAll('.jpg', '.webp') : item.finish)} className="img-fluid" /> */}
            <p className="font-14 text-center text-black mb-0" style={{
                width: 75, height: 42
            }}>
                {/* {item.name.slice(0, 12) + "..."} */}
                {item.name || 'Custom Fabric'}
            </p>
        </div>
    )
}

const FabricSelection = ({ materialCategory, subMaterialCategory, type, showAddFabric }: { showAddFabric: Boolean, type?: string, materialCategory?: any, subMaterialCategory?: any }) => {
    const initLimit = 12
    const [uploadFabricModel, setUploadFabricModel] = useRecoilState<any>(uploadFabricAtom);
    const [fabrics, setFabrics] = useState<any[]>([]);
    const [modelAtomState, setModelAtomState] = useRecoilState<any>(modelAtom);
    const [totalFabrics, setTotalFabrics] = useState<number>(0);
    const [selectedFabric, setSelectedFabric] = useRecoilState<any>(selectedFabricAtom);
    const [skip, setSkip] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [tab, setTab] = useRecoilState<string>(tabAtom);
    const [currentCustomization, setCurrentCustomization] = useRecoilState<any>(currentCustomizationAtom);
    const showFabric = modelAtomState?.categoryName === 'upholstery' ? ['TAB_5', 'TAB_1']?.includes(tab) ? false : true : tab === 'TAB_3' && currentCustomization?.border?.name === 'No' || tab === 'TAB_5' && currentCustomization?.band?.name === 'No'
    const [addNewCustomFabric, setAddNewCustomFabric] = useRecoilState(addNewCustomFabricAtom)
    const [urlData, setUrlData] = useState<string>('')
    const [showModal, setShowModal] = useRecoilState(showModalAtom)
    // Query
    const [getFabricsQuery, { data, loading }] = useLazyQuery(GET_FABRICS, {
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        setSkip(0)
        setFabrics([])
    }, [subMaterialCategory])

    useEffect(() => {
        if (search?.length || skip !== 0) {
            getFabricsQuery({
                variables: {
                    "input": {
                        "limit": initLimit,
                        "skip": skip,
                        "type": fabricType,
                        "key": fabricKey,
                        "companyId": checkOriginCondi ? stoutFabricCompanyId : fabricCompanyId,
                        ...(search !== "" && { name: search }),
                        meterialId: subMaterialCategory,
                        parentMaterialId: materialCategory
                    }
                }
            })
        } else {
            getFabricsQuery({
                variables: {
                    "input": {
                        "limit": initLimit,
                        "skip": 0,
                        "type": fabricType,
                        "key": fabricKey,
                        "companyId": checkOriginCondi ? stoutFabricCompanyId : fabricCompanyId,
                        ...(search !== "" && { name: search }),
                        meterialId: subMaterialCategory,
                        parentMaterialId: materialCategory
                    }
                }
            })
            setSkip(0)
        }

    }, [skip, search, subMaterialCategory, materialCategory])

    useEffect(() => {
        if (data?.getFabrics) {
            if (skip === 0) {
                setFabrics([...data?.getFabrics?.finishes])
            } else {
                setFabrics([...fabrics, ...data?.getFabrics?.finishes])
            }
            setTotalFabrics(data?.getFabrics?.totalFinishes || 0)
        }
    }, [data])

    if (!uploadFabricModel) {
        const fabricAllData = showAddFabric ? [...addNewCustomFabric, ...fabrics] : fabrics
        return (
            <div className={'pt-3'}>
                <div className="control-wrapper fabric-block">
                    {window.screen.availWidth > 944 && !showFabric && (
                        <div
                            className="d-flex align-items-center fabric-input-wrapper w-100"
                        >
                            <div className={`form-group position-relative me-3 `}
                                style={{ maxWidth: '100%' }}
                            >
                                <DebounceInput
                                    className="search-control maxw-100 w-100 "
                                    placeholder={"Search Fabric"}
                                    autoComplete="off"
                                    value={search}
                                    debounceTimeout={500}
                                    autoFocus={true}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                        setTotalFabrics(0)
                                        setFabrics([])
                                        setSkip(0)
                                    }}
                                />
                                <img src="search.png" className="search-icon" />
                            </div>
                        </div>
                    )}

                    {!showFabric && <div className="item-choose w-100 pt-2">
                        <div id="scrollableDiv" >
                            <InfiniteScroll
                                className="d-flex align-items-start flex-wrap item-choose-wrapper pt-2 maxw-100 w-100"
                                style={{ height: 300, overflow: "auto" }}
                                dataLength={fabrics?.length}
                                height={400}
                                next={() => {
                                    if (fabrics?.length < totalFabrics) {
                                        setSkip(fabrics?.length)
                                    }
                                }}
                                hasMore={true}
                                loader={
                                    fabrics?.length < totalFabrics && <div className="loader" key={0}>
                                        <Loader />
                                    </div>
                                }
                                scrollableTarget="scrollableDiv"
                            >
                                {showAddFabric && <div className="fabric-box item-choose me-3 mb-3 cursor-pointer" onClick={() => setUploadFabricModel(true)}>
                                    <img src="Group 1783.png" className="img-fluid add-fabric-button" />
                                    <p className="font-14 text-center text-black mb-0 mt-2">
                                        Add Fabric
                                    </p>
                                </div>
                                }
                                {!fabrics?.length && loading && <Loader />}
                                {fabricAllData?.map((item, index) => (
                                    <FabricBox
                                        key={index}
                                        item={item}
                                        setSelectedFabric={(data: any) => {
                                            setSelectedFabric(data)
                                        }}
                                    />
                                ))}
                            </InfiniteScroll>
                        </div>
                        {tab === 'TAB_1' && <div>
                            <p className="m-0">For Contrasting fabrics on this product, please contact our showroom {checkOriginCondi ? '215.822.3388' : '(919) 742-9303'} </p>
                        </div>
                        }
                    </div>
                    }
                </div>
            </div>
        );
    } else
        return (
            <div>
                <div className="d-flex justify-content-between px-4 pt-3" >
                    <h4 className="font-17">UPLOAD IMAGE</h4>
                    <div className="cursor-pointer" onClick={() => setUploadFabricModel(false)}>X</div>
                </div>
                <div className="p-4">
                    <MyDropzone />
                    <div className="separator my-5">OR</div>
                    <label className="mb-2">URL</label>
                    <div className="d-flex">
                        <input className="reset-input-style me-2 w-100" type="url" onChange={(e) => setUrlData(e.target.value)} placeholder="https://suitepieces.poly9ai" />
                        <Button className="w-min-126px" variant="primary" type="submit" onClick={() => {
                            if (urlData?.length) {
                                // change here
                                // setAddFabricBefore({ ...uploadImage, [fabric?.currentPart?.[0]]: urlData })
                                setShowModal(true)
                                setUploadFabricModel(false)
                            }
                        }}>
                            Upload
                        </Button>
                    </div>
                </div>
            </div>
        );
}

export default FabricSelection;