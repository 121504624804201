import {gql} from '@apollo/client';

export const ADD_PROJECT_SUMMARY = gql`
    mutation addLazarProductSummary($input:inputOfAddProductSummary){
        addLazarProductSummary(input:$input ){
        name
        email
        uniqueCode
        productSummary
        type
        createdAt
        modifiedAt
    }
    }
`