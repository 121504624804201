import { ApolloClient, createHttpLink, HttpLink, InMemoryCache } from "@apollo/client";
// import { setContext } from '@apollo/client/link/context';
import { persistCache } from "apollo-cache-persist";
// import { onError } from "@apollo/client/link/error";
// import { ApolloLink, split } from '@apollo/client';
// import { WebSocketLink } from "@apollo/client/link/ws";
// import { getMainDefinition } from "apollo-utilities";
// import { InMemoryCache } from 'apollo-cache-inmemory';
import { GQL_URL } from "./lib/constant";
// import { getMainDefinition } from '@apollo/client/utilities';

// if (typeof window !== 'undefined') {
//     try {
// See above for additional options, including other storage providers.
// persistCache({
//     cache: new InMemoryCache(),
//     storage: window.localStorage,
// });
//     } catch (error) {
//         console.error('Error restoring Apollo cache', error);
//     }
// }

// const signOut = () => {
//     localStorage.clear();
//     sessionStorage.clear();
//     // createBrowserHistory().push('/')
//     // history.push('/')
//     window.location.href = "/" // redirect user to login page
// };

const clientFun = () => {
    // persistCache({
    //     cache: new InMemoryCache(),
    //     storage: window.localStorage,
    // });
    const httpLink = new HttpLink({ uri: GQL_URL });
    return new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache({addTypename: true}),
    });
    // NEXT_APP_API_URL = http://192.168.0.161:5000
    // const NEXT_APP_GQL_URL = 'https://apis.polyfoundry.com/prod/atlas9'
    // const NEXT_APP_SOCKET_URL = 'wss://apis.polyfoundry.com/prod/atlas9'
    // const NEXT_APP_GQL_URL = 'http://localhost:4000/atlas9'
    // const NEXT_APP_SOCKET_URL = 'ws://localhost:4000/atlas9'
    // const NEXT_APP_GQL_URL = 'http://localhost:5000/graphql'
    // const NEXT_APP_SOCKET_URL = 'ws://localhost:5000/graphql'
    // const httpLink = createHttpLink({
    //     // uri: process.env.NEXT_APP_GQL_URL,
    //     uri: NEXT_APP_GQL_URL,
    //     // uri: "https://api.tykr.com/graphql",
    // });

    // const wsLink = new WebSocketLink({
    //     // local
    //     // uri: `${process.env.NEXT_APP_SOCKET_URL}`,
    //     uri: NEXT_APP_SOCKET_URL,
    //     // dev
    //     // uri: "wss://dev-api.tykr.com/graphql",
    //     // prod
    //     // uri: "wss://api.tykr.com/graphql",
    //     options: {
    //         reconnect: true,
    //         connectionParams: {
    //             authentication: localStorage?.getItem('token'),
    //         },
    //     },
    // });

    // const terminatingLink = split(
    //     ({ query }) => {
    //         const { kind, operation } = getMainDefinition(query);
    //         return kind === "OperationDefinition" && operation === "subscription";
    //     },
    //     wsLink,
    //     httpLink,
    // );



    // const authLink = setContext((_, { headers = {} }) => {
    //     let token
    //     // get the authentication token from local storage if it exists
    //     // if (typeof window !== 'undefined') {
    //     //     token = localStorage?.getItem('token');
    //     // }
    //     // if (token) {
    //     //     headers = { ...headers, "x-token": token };
    //     // }
    //     return { headers };
    // });

    // const errorLink = onError(({ graphQLErrors, networkError }) => {
    //     const loginUser = typeof window !== 'undefined' && JSON.parse(localStorage.getItem("user"))
    //     if (graphQLErrors) {
    //         graphQLErrors.forEach(({ message, extensions, path }) => {
    //             if (extensions.code === 'UNAUTHENTICATED') {
    //                 // if (loginUser?.role) {
    //                 signOut(client)
    //                 // toast.error(message);
    //                 // }
    //             }
    //         });
    //     }

    // if (networkError) {
    //     if (networkError.statusCode === 400) {
    //         if (loginUser?.role) {
    //             signOut(client);
    //         }
    //     }
    // }
    // });

    // return new ApolloClient({

    //     link: ApolloLink.from([authLink, errorLink, terminatingLink]),
    //     cache: new InMemoryCache(),
    // });

}

export const client = clientFun()
