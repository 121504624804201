import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import './App.scss';
import SharePage from './pages/Home/SharePage';
import { Button, Modal } from "react-bootstrap";
import { TOKEN } from './lib/constant';
import { toast } from "react-toastify";

const App = () => {
  const [pwd, setPwd] = useState('')
  const localstorageData = localStorage.getItem('token')
  const saveHandler = () => {
    if (pwd === TOKEN) {
      localStorage.setItem('token', pwd)
      window.location.reload()
    } else {
      toast.warn('Please enter valid password.')
    }
  }
  if ((localstorageData !== TOKEN) || !localstorageData) {
    return <div className='auth-page' >
      <input
        type="password"
        name="name"
        className="pwd-input m-2"
        value={pwd}
        onChange={(e) => setPwd(e.target.value)}
        autoComplete="off"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            saveHandler()
          }
        }}
      />
      <Button
        className=""
        size="lg"
        style={{ backgroundColor: 'black', borderColor: 'black' }}
        type="submit"
        onClick={() => saveHandler()}>
        Enter Password
      </Button>
    </div>

  } else {
    return (
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home category="" />} />
            <Route path="/kinetic" element={<Home category="kinetic" />} />
            <Route path="/manhattan" element={<Home category="manhattan" />} />
            <Route path="/belize" element={<Home category="belize" />} />
            <Route path="/gina-curved" element={<Home category="gina-curved" />} />
            <Route path="/jeremiah" element={<Home category="jeremiah" />} />
            <Route path="/grove" element={<Home category="grove" />} />
            <Route path="/jonathan" element={<Home category="jonathan" />} />
            <Route path="/nathan" element={<Home category="nathan" />} />
            <Route path="/knox" element={<Home category="knox" />} />
            <Route path="/keyWest" element={<Home category="keyWest" />} />
            <Route path="/jensen" element={<Home category="jensen" />} />
            <Route path="/langdon" element={<Home category="langdon" />} />
            <Route path="/garrett" element={<Home category="garrett" />} />
            <Route path="/caracole" element={<Home category="caracole" />} />
            <Route path="/passadena" element={<Home category="passadena" />} />
            <Route path="/slip" element={<Home category="slip" />} />
            <Route path="/bohemia" element={<Home category="bohemia" />} />
            <Route path="/bali" element={<Home category="bali" />} />
            <Route path="/willowbrook" element={<Home category="willowbrook" />} />
            <Route path="/york" element={<Home category="york" />} />
            <Route path="/project/:id" element={<SharePage />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
