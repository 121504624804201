const path = window.location.href;
let preFix;

fetch("https://apis.polyfoundry.com/prod/check")
  .then((res) => res.text())
  .then((res) => {
    console.log("res: ", res);
    preFix = res;
    return res;
  })
  .catch((err) => console.log(err));


if (path.includes("netlify")) {
  module.exports = {
    PILLOW_CATEGORY: "",
    PILLOW_SUB_CATEGORY: "",
    API_ENDPOINT: "https://apis.polyfoundry.com/prod/apis/v2",

    GET_URL: (path) => {
      if (path && preFix) {
        return `https://${preFix}.poly9.ai/public/${path}`
      }
      return
    },
    compnayId: "5f991484a0d40e0e988e1722",
  };
  // } else if (path.includes("netlify")) {
  //   module.exports = {
  //     PILLOW_CATEGORY: "",
  //     PILLOW_SUB_CATEGORY: "",
  //     API_ENDPOINT: "https://test.virtuosopastiche.com/test/apis/v2/",
  //     GET_URL: (path) => `https://${preFix}.pothigara.com/public/${path}`,
  //     compnayId: "5f991484a0d40e0e988e1722",
  //   };
} else {
  module.exports = {
    PILLOW_CATEGORY: "",
    PILLOW_SUB_CATEGORY: "",
    // API_ENDPOINT: "http://localhost:4000/apis/v2/",
    API_ENDPOINT: "https://apis.polyfoundry.com/prod/apis/v2",
    // API_ENDPOINT: "https://test.virtuosopastiche.com/test/apis/v2/",
    // GET_URL: (path) => `https://www.pothigara.com/public/${path}`,
    GET_URL: (path) => {
      if (path && preFix) {
        return `https://${preFix}.poly9.ai/public/${path}`
      }
      return
    },
    // compnayId:"5f991484a0d40e0e988e1722",
    compnayId: "5d9ecbd973fa720b9cce4308",
  };
}

// https://test.virtuosopastiche.com/test/apis/v2/
