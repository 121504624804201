import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { Notification } from "../../../lib/Notification";
import { addToProjectImageAtom } from "../../../state";

const AccessLinkPage = ({ modalShow, setModalShow, uniqueCode, setUniqueCode }) => {
    const [addToProjectImage, setAddToProjectImage] = useRecoilState(addToProjectImageAtom);
    const copyUrl = `${window.location.origin}/project/${uniqueCode}`
    const [linkCopy, setLinkCopy] = useState(false)

    const copyLink = () => {
        var text = copyUrl
        var elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
        toast.success(Notification['Copy Link'])
        setLinkCopy(true)
    }

    return (
        <Modal
            show={true}
            onHide={() => {
                setUniqueCode('')
                setModalShow({
                    ...modalShow,
                    modalTwo: false
                })
            }}
            className="project-saved-modal"
        >
            <Modal.Header closeButton></Modal.Header>
            {uniqueCode && <Modal.Body style={{ width: '74%' }}>
                <div>
                    <div className="text-center">
                        <img src="modal-ty.png" alt="" className="img-fluid modal-ty" />
                        <h2 className="title mb-2 pt-2 mt-3">Your Project is saved!</h2>
                        <h5 className="sub-title mb-5">
                            Use this link to access your project.
                        </h5>
                    </div>
                    <div className=" w-100">
                        <div className="mb-3 d-flex align-items-center flex-wrap">
                            <label style={{ flex: "1" }} className="w-100  me-2 label-input" >
                                <input
                                    type="text"
                                    name="name"
                                    className="w-100 copy-link"
                                    value={copyUrl}
                                    readOnly
                                />
                            </label>
                            <button className="btn btn-primary " onClick={() => copyLink()}>{linkCopy ? 'Copied!' : 'Copy'}</button>
                        </div>
                    </div>
                    <a
                        href={copyUrl}
                        target='_blank'
                        className="mt-2 mb-3 preview-link text-left text-dark me-auto w-100"
                    >
                        Preview link in new tab
                    </a>
                    <div className="text-center mt-3 pt-2">
                        <div className="row">
                            {addToProjectImage?.map((image, index) => {
                                return <div className="col-6" key={index}> <img
                                    src={image}
                                    key={image}
                                    alt=""
                                    className="img-fluid "
                                />
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            }
        </Modal>
    )
}

export default AccessLinkPage