// import "@google/model-viewer";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  addToProjectAtom,
  addToProjectImageAtom,
  modelAtom,
  preFixAtom,
  selectedImageScaletom,
  upholsteryAtom,
  upholsteryItemAtom,
  uploadImageAtom,
} from "../../state";
import { GET_URL } from "../../constans";
const textures = {};

const UpholsteryModelViewer = ({ showButton }) => {
  const modelViewer = document.querySelector("#model-viewer")
  const upholstery = useRecoilValue(upholsteryAtom);
  const upholsteryItem = useRecoilValue(upholsteryItemAtom)
  const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
  const preFix = useRecoilValue(preFixAtom);
  const [addToProject, setAddToProject] = useRecoilState(addToProjectAtom);
  const [addToProjectImage, setAddToProjectImage] = useRecoilState(addToProjectImageAtom);
  const getCheckSupport = localStorage.getItem('checkSupport')
  const uploadImage = useRecoilValue(uploadImageAtom)
  const inputValue = useRecoilValue(selectedImageScaletom);

  // useEffect(() => {
  //   modelAtomValue.src.replace('app', preFix) && preFix && getUVModel();
  // }, [upholstery.partWiseFabric, modelAtomValue.src]);

  // in-built fabric apply at that time apply uv model
  useEffect(() => {
    if (upholstery?.partWiseFabric) {
      (async () => {
        setTimeout(async () => {
          await getUVModel()
        }, 1000)
      })()
    }
  }, [upholstery?.partWiseFabric, modelAtomValue, modelViewer]);

  // custom fabric apply at that time apply uv model
  // useEffect(() => {
  //   if (uploadImage) {
  //     (async () => {
  //       setTimeout(async () => {
  //         await getUVModel()
  //         // await getImageUVModel()
  //       }, 3000)
  //     })()
  //   }
  // }, [modelViewer, uploadImage, inputValue]);


  useEffect(() => {
    if (uploadImage) {
      createTextureImages('Fabric', uploadImage);
    }
  }, [modelAtomValue, modelViewer, uploadImage, upholstery]);

  const createTextureImages = (imageType, image) => {
    createAndApplyTextureImage('baseColorTexture', image, imageType);
    createAndApplyTextureImage('normalTexture', 'normal.jpg', imageType);
  }

  const createAndApplyTextureImage = async (channel, path, keys) => {
    if (modelViewer?.model) {
      var texture = await modelViewer.createTexture(path);
      const model = modelViewer.model;
      const parts = model[Object.getOwnPropertySymbols(model)[1]];
      const meterialIndexies = [];
      const partWiseIndex = {};

      parts.forEach((part) => {
        const index = part.initialMaterialIdx;
        if (index || index == 0) {
          partWiseIndex[index] = part.name;
          if (keys?.includes(part.name)) {
            meterialIndexies.push(index);
          }
        }
      });

      meterialIndexies.forEach((index) => {
        const material = modelViewer.model.materials[index];
        if (channel.includes("base") || channel.includes("metallic")) {
          material.pbrMetallicRoughness[channel].setTexture(null);
          material.pbrMetallicRoughness[channel].setTexture(texture);
        } else {
          material[channel].setTexture(null);
          material[channel].setTexture(texture);
        }
      });
    }
  }


  useEffect(() => {
    updateMaterial();
  }, [upholstery.partWiseFabric, modelAtomValue.src]);

  const getUVModel = async () => {
    try {
      if (modelViewer?.model) {
        const model = modelViewer.model;
        if (!model) return;

        let keys = uploadImage ? ['Fabric'] : Object.keys(upholstery.partWiseFabric)
        if (keys.length === 0) {
          return;
        }
        const parts = model[Object.getOwnPropertySymbols(model)[1]];
        let meterialIndexies = [];

        for (const key of keys) {
          parts.forEach((part) => {
            if (key === part.name) {
              const index = part.initialMaterialIdx
              meterialIndexies = {
                ...meterialIndexies,
                [key]: index,
              };
            }
          });
        }
        for (const fabricKey of keys) {
          if (typeof meterialIndexies[fabricKey] === 'number' && isFinite(meterialIndexies[fabricKey])) {
            const material = modelViewer.model.materials[meterialIndexies[fabricKey]];
            const diffuse_map = material.pbrMetallicRoughness['baseColorTexture'].texture.sampler;
            const normal_map = material.normalTexture.texture.sampler;
            const checkImage = uploadImage;
            const xValue = Number(!checkImage ? upholstery.partWiseFabric[fabricKey].repeatX : inputValue?.Horizontal)
            const yValue = Number(!checkImage ? upholstery.partWiseFabric[fabricKey].repeatY : inputValue?.Vertical)
            const scale = {
              u: xValue || 0,
              v: yValue || 0,
            };
            if (scale.u || scale.v) {
              modelViewer.exposure = 0.65
              diffuse_map.setScale(scale);
              normal_map.setScale(scale);
            }
          }
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: UpholsteryModelViewer.jsx:150 ~ getUVModel ~ error:", error)
    }
  };

  const createAndApplyTexture = async (channel, modelPartName, fabricImg, mergeImage) => {
    const base64Image = fabricImg?.search('data:image/png;base64') !== -1 ? true : false
    let imageConvert = fabricImg
    if (getCheckSupport === 'true' && !base64Image) {
      imageConvert = fabricImg?.replaceAll('.jpeg', '.webp')
    }
    try {
      if (modelViewer?.model) {
        let texture = null;
        if (textures[fabricImg]) {
          texture = textures[fabricImg];
        } else {
          texture = await modelViewer.createTexture(base64Image ? fabricImg : GET_URL(imageConvert));
          textures[fabricImg] = texture;
        }

        const model = modelViewer.model;
        const parts = model[Object.getOwnPropertySymbols(model)[1]];

        const meterialIndexies = [];
        const partWiseIndex = {};

        parts.forEach((part) => {
          const index = part.initialMaterialIdx
          partWiseIndex[index] = part.name;
          if (modelPartName === part.name) {
            meterialIndexies.push(index);
          }
        });

        meterialIndexies.forEach(index => {
          const material = modelViewer.model.materials[index];
          const pbrMR = material.pbrMetallicRoughness;

          if (channel.includes("base") || channel.includes("metallic")) {
            pbrMR[channel].setTexture(texture);
          } else {
            material[channel].setTexture(texture);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateMaterial = async () => {
    let keys, material;

    keys = Object.keys(upholstery.partWiseFabric);
    // keys = ['Fabric'];
    material = upholstery;

    if (keys.length === 0) {
      return;
    }
    for (const key of keys) {
      if (material.partWiseFabric[key]) {
        await createAndApplyTexture(
          "baseColorTexture",
          key,
          material.partWiseFabric[key].diffuse
        );
        await createAndApplyTexture(
          "metallicRoughnessTexture",
          key,
          material.partWiseFabric[key].metallicRoughness
        );
        await createAndApplyTexture(
          "normalTexture",
          key,
          material.partWiseFabric[key].normal
        );
      }
    }

  };

  if (modelViewer) {
    modelViewer.addEventListener("load", (data) => {
      updateMaterial();
    });
  }

  const addToProjectHandler = () => {
    document.querySelector('model-viewer').fieldOfView = 10
    document.querySelector("#model-viewer").cameraOrbit = '0deg 75deg 90%'
    setTimeout(() => {
      const base64Image = document.querySelector("#model-viewer").toDataURL('image/png')
      const cehckIndex = addToProject?.length ? Number(addToProject?.[0]?.seriesName?.slice(1)) : 0
      let paintName = {}
      let newPaint = {}
      let legSelection
      for (let key in upholstery?.partWiseFabric) {
        const value = upholstery?.partWiseFabric[key]
        newPaint = {
          ...newPaint, [key]: {
            _id: value?._id,
            diffuse: value?.diffuse,
            metallicRoughness: value?.metallicRoughness,
            normal: value?.normal,

          }
        }
        legSelection = value?.legSelection
        paintName = { ...paintName, [key]: value?.name }
      }

      const updateFabric = {
        ...upholstery,
        partWiseFabric: newPaint
      }
      const addData = {
        name: updateFabric?.name,
        quantity: 1,
        base64Image: base64Image,
        fabric: upholstery,
        legSelection,
        modelAtomValue: {
          ...modelAtomValue,
          src: document.querySelector("#model-viewer").src || modelAtomValue.src
        },
        upholsteryItem,
        paintName,
        seriesName: cehckIndex + 1 > 9 ? `S${cehckIndex + 1}` : `S0${cehckIndex + 1}`
      }
      let checkData
      const checkSameName = addToProject?.filter(data => data?.name === updateFabric?.name)

      if (checkSameName?.length > 0) {
        checkData = addToProject?.findIndex(data => data?.name === updateFabric?.name && data?.type === 'upholstery' && JSON.stringify(data?.fabric) === JSON.stringify(updateFabric)
          && JSON.stringify(data?.upholsteryItem) === JSON.stringify(upholsteryItem)
          && JSON.stringify(data?.paintName) === JSON.stringify(paintName)
        )
      } else {
        checkData = -1
      }

      if (checkData === -1) {
        setAddToProjectImage([base64Image, ...addToProjectImage])
        setAddToProject([addData, ...addToProject])
      } else {
        const updatedData = addToProject?.map((data, index) => {
          if (index === checkData) {
            return { ...data, quantity: data.quantity + 1 }
          } else {
            return data
          }
        })
        setAddToProject(updatedData)
      }
      document.querySelector("#model-viewer").cameraOrbit = '0deg 75deg 105%'
    }, 1000)
  }

  document.querySelector("#model-viewer")?.addEventListener("progress", (event) => {
    if (event.detail.totalProgress === 1) {
      setModelAtomValue({
        ...modelAtomValue,
        loading: 100,
      })
    }
  })
  if (modelAtomValue.src.replace('app', preFix) && preFix) {
    return (
      <>
        <div id="card" style={{
          opacity: modelAtomValue.loading === 100 ? '1' : '0',
          transition: 'opacity 1s ease-in-out'
        }}>
          <model-viewer
            tone-mapping="commerce"
            src={modelAtomValue.src.replace('app', preFix)}
            camera-controls
            disable-pan
            shadow-intensity="1"
            camera-orbit='0deg 75deg 85%'
            style={{ height: "57vh", width: "100%" }}
            id="model-viewer"
          ></model-viewer>
        </div>
        {showButton && <button
          className="btn btn-outline-primary mb-3"
          style={{
            maxWidth: 50,
            width: '100%',
            margin: '0 auto'
          }}
          onClick={() => addToProjectHandler()}
        // disabled={loader ? true : false}
        >
          Add to Selection
        </button>
        }
      </>
    );
  } else {
    return <></>
  }
};

export default UpholsteryModelViewer;
