import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import content from "../../../content";
import { modelAtom, upholsteryAtom } from "../../../state";
import Customize from './customization'

const StoutInspirations = ({ category, loader }) => {
    const categoryUpdate = category || "caracole"
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [upholsteryAtomState, setUpholsteryAtomState] = useRecoilState(upholsteryAtom);
    const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
    useEffect(() => {
        const firstRecord = content?.inspirations1?.[categoryUpdate]?.[0]
        setSelectedProduct(0);
        if (firstRecord?.name) {
            setUpholsteryAtomState({
                ...upholsteryAtomState,
                ...firstRecord,
                pillowShow: firstRecord?.pillowShow || false,
                category: firstRecord?.category,
                currentPart: categoryUpdate === 'grove' ? ['Inner'] : ['Fabric']
            })
        }
        if (firstRecord?.modelName) {
            setModelAtomValue({
                ...modelAtomValue,
                src: firstRecord?.modelName,

            })
        }
    }, [categoryUpdate]);

    return (
        <Customize
            category={categoryUpdate}
            selectedProduct={selectedProduct}
        />
    );
};

export default StoutInspirations;