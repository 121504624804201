import FabricSelection from "./FabricSelection";
import { GET_URL } from "../../../constans";
import { checkOriginCondi, material, stoutMaterial, stoutSubMaterial, subMaterial } from '../../../lib/constant';
import { useRecoilState } from "recoil";
import { groveSelectionAtom, legSelectionAtom, upholsteryItemAtom, uploadImageAtom } from "../../../state";

const UpholsteryTabData = ({
    tab, upholsteryAtomState, setUpholsteryAtomState, groveCategory
}) => {
    const [legSelection, setLegSelection] = useRecoilState(legSelectionAtom)
    const [upholsteryItem, setUpholsteryItem] = useRecoilState(upholsteryItemAtom)
    const subMaterialList = checkOriginCondi ? stoutSubMaterial : subMaterial
    const materialList = checkOriginCondi ? stoutMaterial : material
    const [uploadImage, setUploadImage] = useRecoilState(uploadImageAtom)
    const [groveSelection, setGroveSelection] = useRecoilState(groveSelectionAtom)

    const renderData = () => {
        switch (tab) {
            case "TAB_1":
                return (
                    <div className="p-4">
                        <div className="border-bottom w-100">
                            <p className="text-color font-17">FABRICS</p>
                            <div className="d-flex align-items-center pb-3">
                                <div className="small-box ">
                                    {uploadImage
                                        ? <img src={uploadImage} alt="" style={{ height: '100%', objectFit: 'contain' }} />
                                        : <>
                                            {upholsteryAtomState?.partWiseFabric?.Fabric?.finishThumb ?
                                                <img src={GET_URL(upholsteryAtomState?.partWiseFabric?.Fabric?.finishThumb)} alt="" />
                                                :
                                                <img src={'defalutImage.jpg'} alt="" />
                                            }
                                        </>}
                                </div>
                                <p
                                    style={{ marginLeft: "10px", marginTop: "20px" }}
                                    className="font-14"
                                >
                                    <span className="color-gray">{upholsteryAtomState?.name} </span><br />
                                    {uploadImage ? 'Custom Fabric' : upholsteryAtomState?.partWiseFabric?.Fabric?.name || 'Plain Fabric'}
                                </p>
                            </div>
                        </div>
                        {groveCategory && <div className={`d-flex pt-3`}>
                            <div onClick={() => {
                                setUpholsteryAtomState({ ...upholsteryAtomState, currentPart: ['Inner'] })
                                setGroveSelection('Inner')
                            }}
                                className={`side-box ${groveSelection === "Inner" ? 'active' : ''} me-3 position-relative`}>
                                <p className="font-14 text-black text-center mb-0">
                                    Inside
                                </p>
                            </div>
                            <div onClick={() => {
                                setUpholsteryAtomState({ ...upholsteryAtomState, currentPart: ['Outer'] })
                                setGroveSelection('Outer')
                            }}
                                className={`side-box ${groveSelection === "Outer" ? 'active' : ''} me-3 position-relative`}>
                                <p className="font-14 text-black text-center mb-0">
                                    Outside
                                </p>
                            </div>
                        </div>
                        }
                        <FabricSelection
                            materialCategory={materialList.pillowFabric}
                            subMaterialCategory={subMaterialList.pillowFabric}
                            header="UPHOLSTERY"
                            showAddFabric={true}
                        />
                    </div>
                );
            case "TAB_2":
                return (
                    <div className="p-4">
                        <div className="border-bottom w-100">
                            <p className="text-color font-17">LEGS</p>
                            <div className="d-flex align-items-center pb-3">
                                <div className="small-box ">
                                    {upholsteryAtomState?.partWiseFabric?.Legs?.finishThumb ?
                                        <img src={GET_URL(upholsteryAtomState?.partWiseFabric?.Legs?.finishThumb)} alt="" />
                                        :
                                        <img src={'defalutImage.jpg'} alt="" />
                                    }
                                </div>
                                <p
                                    style={{ marginLeft: "10px", marginTop: "20px" }}
                                    className="font-14"
                                >
                                    <span className="color-gray">{upholsteryAtomState?.name} </span><br />
                                    {upholsteryAtomState?.partWiseFabric?.Legs?.name || 'Plain Wood'}
                                </p>
                            </div>
                        </div>
                        <div className={`d-flex mt-3`}>
                            {upholsteryAtomState?.options?.map(data => {
                                return <div
                                    key={data}
                                    onClick={() => {
                                        setLegSelection(data)
                                    }}
                                    className={`side-box ${legSelection === data ? 'active' : ''} me-3 position-relative`}>
                                    <p className="font-14 text-black text-center mb-0"> {data} </p>
                                </div>
                            })}

                        </div>
                        <FabricSelection
                            materialCategory={legSelection === "Wood" ? materialList.legWood : legSelection === "Metal" ? materialList.metalWood : material?.pillowFabric}
                            subMaterialCategory={legSelection === "Wood" ? subMaterialList.legWood : legSelection === "Metal" ? subMaterialList.metalWood : subMaterial?.pillowFabric}
                            header="UPHOLSTERY"
                            showAddFabric={false}
                        />
                    </div>
                );
            case "TAB_3":
                return <div className="p-4">
                    <div className="border-bottom w-100">
                        <p className="text-color font-17">SEAT CUSHIONS</p>
                    </div>
                    <div>Memory Form</div>
                    <div className={`d-flex pb-3 mb-3 mt-3`}>
                        <div onClick={() => {
                            setUpholsteryItem({
                                ...upholsteryItem,
                                platinumSeat: 'Yes'
                            })
                        }}
                            className={`side-box ${upholsteryItem?.platinumSeat === "Yes" ? 'active' : ''} me-3 position-relative`}>
                            <p className="font-14 text-black text-center mb-0">
                                Yes
                            </p>
                        </div>
                        <div
                            className={`side-box ${upholsteryItem?.platinumSeat === "No" ? 'active' : ''} me-3 position-relative`}
                            onClick={() => {
                                setUpholsteryItem({
                                    ...upholsteryItem,
                                    platinumSeat: 'No'
                                })
                            }}
                        >
                            <p className="font-14 text-black text-center mb-0">
                                No
                            </p>
                        </div>
                    </div>
                </div>
            case "TAB_4":
                return (
                    <div className="p-4">
                        <div className="border-bottom w-100">
                            <p className="text-color font-17">PILLOW</p>
                            <div className="d-flex align-items-center pb-3">
                                <div className="small-box ">
                                    {uploadImage
                                        ? <img src={uploadImage} alt="" style={{ height: '100%', objectFit: 'contain' }} />
                                        : <>
                                            {upholsteryAtomState?.partWiseFabric?.Fabric?.finishThumb ?
                                                <img src={GET_URL(upholsteryAtomState?.partWiseFabric?.Fabric?.finishThumb)} alt="" />
                                                :
                                                <img src={'defalutImage.jpg'} alt="" />
                                            }
                                        </>}
                                </div>
                                <p
                                    style={{ marginLeft: "10px", marginTop: "20px" }}
                                    className="font-14"
                                >
                                    <span className="color-gray">{upholsteryAtomState?.name} </span><br />
                                    {uploadImage ? 'Custom Fabric' : upholsteryAtomState?.partWiseFabric?.Fabric?.name || 'Plain Fabric'}
                                </p>
                            </div>
                        </div>
                        <FabricSelection
                            materialCategory={materialList.pillowFabric}
                            subMaterialCategory={subMaterialList.pillowFabric}
                            header="UPHOLSTERY"
                            showAddFabric={false}
                        />
                    </div>
                );
            // return renderItem(PLATINUM_Seat_Cushions?.name, 'platinumSeat', platinumSeat, PLATINUM_Seat_Cushions?.options)
            // case "TAB_3":
            //     return renderItem(Contrasting_Fabric?.name, 'contrasting', contrasting, Contrasting_Fabric?.options)
            // case "TAB_4":
            //     return renderItem(Cushion_Fill?.name, 'cushion', cushion, Cushion_Fill?.options)
            // case "TAB_5":
            //     return renderItem(Feather_Float_Blend?.name, 'featherFloat', featherFloat, Feather_Float_Blend?.options)
            // case "TAB_6":
            //     return renderItem(Trillium?.name, 'trillium', trillium, Trillium?.options)
            // case "TAB_7":
            //     return renderItem(Memory_Foam?.name, 'memoryFoam', memoryFoam, Memory_Foam?.options)
            // case "TAB_8":
            //     return renderItem(Casters?.name, 'casters', casters, Casters?.options)
            // // case "TAB_9":
            // //     return renderItem(Wood_Finish?.name, 'woodFinish', woodFinish, Wood_Finish?.options)
            // case "TAB_9":
            //     return renderItem(Nailhead_Finishes?.name, 'nailhead', nailhead, Nailhead_Finishes?.options)
            // case "TAB_10":
            //     return renderItem(Metal_Swivel_Base_Options?.name, 'metalSwivel', metalSwivel, Metal_Swivel_Base_Options?.options)
        }
    }

    return (
        <div>{renderData()}</div>
    )
}

export default UpholsteryTabData
