import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    upholsteryAtom, tabAtom, currentCustomizationAtom, uploadImageAtom,
    selectedImageScaletom,
    boxDimentionAtom, beforeSelectedImageScaletom, selectedCustomFabricAtom,
    addNewCustomFabricAtom,
    showModalAtom,
    legSelectionAtom,
} from "../../../state";
import { checkOriginCondi, upholsterySidebar } from "../../../lib/constant";
import UpholsteryTabData from "./UpholsteryTabData";
import UpholsteryModelViewer from "../../../components/GoogleModelViewer/UpholsteryModelViewer";
import CropImage from "../../../components/CropImage";

const Customization = ({ category, selectedProduct }) => {
    const smallSize = {
        name: "12x20",
        image: "12x20.png",
        setSize: '12x20'
    }
    const bigSize = {
        name: "20x20",
        image: "20.png",
        setSize: '20x20'
    }
    const [inputValue, setInputValue] = useRecoilState(selectedImageScaletom);
    const [inputSetValue, setInputSetValue] = useRecoilState(beforeSelectedImageScaletom);
    const [tab, setTab] = useRecoilState(tabAtom);
    const [upholsteryAtomState, setUpholsteryAtomState] = useRecoilState(upholsteryAtom);
    const [currentCustomization, setCurrentCustomization] = useRecoilState(currentCustomizationAtom);
    const [showModal, setShowModal] = useRecoilState(showModalAtom)
    const [uploadImage, setUploadImage] = useRecoilState(uploadImageAtom)
    const boxDimentions = useRecoilValue(boxDimentionAtom)
    const [addNewCustomFabric, setAddNewCustomFabric] = useRecoilState(addNewCustomFabricAtom)
    const [selectedCustomFabric, setSelectedCustomFabricAtom] = useRecoilState(selectedCustomFabricAtom)
    const [legSelection, setLegSelection] = useRecoilState(legSelectionAtom)
    const showTabData = checkOriginCondi ? upholsterySidebar.slice(0, 1) : upholsterySidebar
    const UseHeightAndWidth = async () => {
        if (uploadImage) {
            const final_img = uploadImage
            var image = new Image();
            image.src = final_img;
            await image.decode();
            const { height: img_height, width: img_width } = image;
            const { Vertical: vertical_repeat, Horizontal: horizontal_repeat, Scale: multiply = 1 } = inputSetValue;

            if (vertical_repeat !== 0 && horizontal_repeat !== 0) {
                let height_inch = (img_height * vertical_repeat) / boxDimentions.height
                let width_inch = (img_width * horizontal_repeat) / boxDimentions.width
                const pilloSize = currentCustomization.size.name
                const heightDimention = pilloSize === '12x20' ? 0.45 : 0.85
                const widthDimention = pilloSize === '12x20' ? 0.75 : 0.85
                let t_c_h = height_inch * heightDimention
                let t_c_w = width_inch * widthDimention
                let V = (Number(pilloSize.slice(0, 2)) / t_c_h) * multiply

                let U = (20 / t_c_w) * multiply
                if (V >= 0 && U >= 0) {
                    setInputValue({
                        Horizontal: U,
                        Vertical: V,
                    })
                    if (selectedCustomFabric?.id) {
                        const newData = addNewCustomFabric?.map(data => data?.id === selectedCustomFabric?.id ? {
                            ...data, ...{
                                setHorizontal: U,
                                setVertical: V,
                            }
                        } : data)
                        setAddNewCustomFabric(newData)
                    }
                }
            };
        }
    }

    useEffect(() => {
        UseHeightAndWidth()
    }, [inputSetValue, uploadImage])

    useEffect(() => {
        setTab("TAB_1");
    }, [category])

    useEffect(() => {
        switch (selectedProduct) {
            case 0:
                setCurrentCustomization({
                    ...currentCustomization,
                    size: smallSize,
                })
                break;
            case 1:
                setCurrentCustomization({
                    ...currentCustomization,
                    size: bigSize,
                })
                break;
            case 2:
                setCurrentCustomization({
                    ...currentCustomization,
                    size: smallSize,
                })
                break;
            case 3:
                setCurrentCustomization({
                    ...currentCustomization,
                    size: bigSize,
                })
                break;
            default:
                break;
        }
    }, [selectedProduct])

    const loadCategoryCustomizationTab = () => {
        return <ul>
            {showTabData?.map(data => {
                if (data.name !== 'Pillow' || upholsteryAtomState?.pillowShow) {
                    return <li
                        className={tab === data?.key ? "active" : ""}
                        onClick={() => {
                            setTab(data?.key)
                            setUpholsteryAtomState({ ...upholsteryAtomState, currentPart: data?.parts })
                            if (data?.name === 'Legs') {
                                setLegSelection(upholsteryAtomState?.options?.[0])
                            }
                        }}
                        key={data?.name}
                    >
                        <img
                            src={data?.img}
                            className="img-fluid"
                            alt="product-choose"
                        />
                        <p className="tab-text mb-0 mt-2" style={{ textAlign: 'center' }}>
                            {category === 'grove' && data?.name === 'Legs' ? "Base" : data?.name}
                        </p>
                    </li>
                }
            })}
        </ul>

    }


    return (
        <>
            <div className="product-title text-center w-100">DESIGNER Customization</div>
            <div className="product-info-row">
                {/* if image upload then show crop option otherwise show normal options */}
                {showModal ?
                    <CropImage />
                    : <div className="row m-0">
                        <div className="col-lg-7 col-xl-7 left-part mt-rem-6 mt-0 d-flex justify-content-between" style={{
                            flexDirection: 'column',
                        }}>
                            <UpholsteryModelViewer showButton={true} />
                        </div>
                        <div className="col-lg-5 col-xl-5 right-part text-left mobile-shadow">
                            <div className="d-flex align-items-stretch tab-item-wrapper">
                                {loadCategoryCustomizationTab()}
                                <div className="item-infos me-4 w-100">
                                    <div className="tab-content">{
                                        <UpholsteryTabData
                                            upholsteryAtomState={upholsteryAtomState}
                                            setUpholsteryAtomState={setUpholsteryAtomState}
                                            tab={tab}
                                            groveCategory={category === 'grove'}
                                        />
                                    }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Customization;