import { atom } from "recoil";

export const addToProjectAtom = atom({
  key: "addToProject",
  default: [],
});

export const legSelectionAtom = atom({
  key: "legSelection",
  default: "",
});

export const groveSelectionAtom = atom({
  key: "groveSelection",
  default: "Inner",
});

export const addNewCustomFabricAtom = atom({
  key: "addNewCustomFabric",
  default: [],
});

export const selectedCustomFabricAtom = atom({
  key: 'selectedCustomFabric',
  default: { id: '' }
})

export const cropperSelectionAtom = atom({
  key: 'cropperSelection',
  default: {}
})

export const aspectRationAtom = atom({
  key: 'aspectRation',
  default: {
    show: false,
    width: '',
    height: '',
    ratio: 1
  }
})

export const addToProjectImageAtom = atom({
  key: "addToProjectImage",
  default: [],
});
export const addFabricBeforeAtom = atom({
  key: "addFabricBefore",
  default: '',
});
export const addFabricToggleAtom = atom({
  key: "addFabricToggle",
  default: false,
});

export const canvasToggleRenderAtom = atom({
  key: "canvasToggleRender",
  default: [],
});

export const loaderAtom = atom({
  key: "loader",
  default: false,
});

export const uploadFabricAtom = atom({
  key: "uploadFabric",
  default: false,
});

export const boxDimentionAtom = atom({
  key: "boxDimention",
  default: {
    height: 409,
    width: 409
  },
});

export const fabricAtom = atom({
  key: "fabric",
  default: {
    currentPart: [],
    partWiseFabric: {},
  },
});

export const selectedFabricAtom = atom({
  key: "selectedFabric",
  default: {},
});
export const selectedImageScaletom = atom({
  key: "selectedImageScale",
  default: {
    Horizontal: 0,
    Vertical: 0,
    Scale: 1
  },
});
export const beforeSelectedImageScaletom = atom({
  key: "beforeSelectedImageScale",
  default: {
    Horizontal: 0,
    Vertical: 0,
    Scale: 1
  }
});

export const upholsteryItemAtom = atom({
  key: "upholsteryItem",
  default: {
    platinumSeat: 'No'
  }
})

export const currentPillowStateAtom = atom({
  key: "currentPillowState",
  default: 'Double Banded Lumbar Pillow',
});

export const currentCustomizationAtom = atom({
  key: "currentCustomization",
  default: {
    color: "",
    pattern: "",
    size: {
      name: "12x20",
      image: "12x20.png",
      setSize: '12x20'
    },
    quantity: 1,
    border: {
      name: 'No',
      image: "pillows-no.png",
    },
    band: {
      name: 'No',
      image: "pillows-no.png",
      type: 'No'
    },
    insertType: {
      name: 'Pillow Cover Only',
      image: 'Pillow Cover Only.png'
    }
  },
});

export const tabAtom = atom({
  key: "tab",
  default: 'TAB_1',
});

export const uploadImageAtom = atom({
  key: "uploadImageAtom",
  default: ''
});

export const preFixAtom = atom({
  key: "preFix",
  default: 'app'
});

export const showModalAtom = atom({
  key: "showModal",
  default: false
});

export const selectedAlphaAtom = atom({
  key: "selectedAlphaAtom",
  default: {
    name: 'Clear Wax',
    type: 'wax',
    image: 'https://customer.polynine.com/poc/suitepieces/fabric/vintage/Clear%20Wax.png',
    index: 1,
    exposure: 0.7
  },
});
export const selectedEdgeAtom = atom({
  key: "selectedEdge",
  default: [],
});

export const vintageAtom = atom({
  key: "vintage",
  default: {
    name: "Tall_Vintage_Dresser",
    handle: "Black Walnut Wood And Gold Ends TKnob",
    sortName: 'Wood T-Knob',
    paint: "",
    finish: "",
    currentPart: ["Base"],
    image: 'handle_thumb/Black_Walnut_Wood_And_Gold_Ends_TKnob.jpg',
    // currentPart: ["Wood", "Bush", "MDF", "Base", "Drawer"],
    partWiseFabric: {},
  },
});

export const upholsteryAtom = atom({
  key: "upholstery",
  default: {
    name: "Kinetic",
    currentPart: ['Fabric'],
    partWiseFabric: {},
  },
});


export const modelAtom = atom({
  key: "model",
  default: {
    categoryName: "",
    src: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/342734c3-69ea-4148-832f-58d9a4d0a1c5/81268c8a-cfde-4f59-bef5-805a076533ba.gltf",
    loading: 100
  },
});
