import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import content from "../../../content";
import { modelAtom, upholsteryAtom } from "../../../state";
import Customize from '../Inspirations/customization'

const Inspirations = ({ category, loader }) => {
    const categoryUpdate = category || "kinetic"
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [upholsteryAtomState, setUpholsteryAtomState] = useRecoilState(upholsteryAtom);
    const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
    useEffect(() => {
        const firstRecord = content?.inspirations?.[categoryUpdate]?.[0]
        setSelectedProduct(0);
        if (firstRecord?.name) {
            setUpholsteryAtomState({
                ...upholsteryAtomState,
                ...firstRecord,
                pillowShow: firstRecord?.pillowShow || false,
                category: firstRecord?.category,
                currentPart: categoryUpdate === 'grove' ? ['Inner'] : ['Fabric']
            })
        }
        if (firstRecord?.modelName) {
            setModelAtomValue({
                ...modelAtomValue,
                src: firstRecord?.modelName,

            })
        }
    }, [categoryUpdate]);

    // const capitalizeFirstLetter = (inputString) => {
    //     return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
    // }

    return (
        <>
            <div className="d-flex product-row" style={{ alignItems: 'top', overflow: "auto", justifyContent: ['nathan', 'jensen', 'langdon', 'garrett'].includes(categoryUpdate) ? 'start' : 'center' }}>
                {content?.inspirations[categoryUpdate]?.map((res, index) => (
                    <div className='text-center d-flex flex-column align-items-center' key={index}>
                        <div className={`product-info-box mx-3 
                            ${index === selectedProduct ? 'active-category' : ''} `}
                            key={index}
                            onClick={() => {
                                if (!loader) {
                                    setSelectedProduct(index)
                                    setUpholsteryAtomState({
                                        ...upholsteryAtomState,
                                        name: res.name,
                                        category: res.category,
                                        sku: res.sku,
                                        price: res.price,
                                        pillowShow: res?.pillowShow || false,
                                        ...res
                                    })
                                    setModelAtomValue({
                                        ...modelAtomValue,
                                        src: res.modelName
                                    })
                                }
                            }}
                        >
                            <img src={res.image} className="img-fluid" alt={res.name} />
                        </div>
                        <p className={`w-75 break-word mb-0 m-font color-gray`} style={{ fontSize: 13 }} >
                            {res?.sku}
                        </p>
                        <p className={`w-75 break-word mb-0 m-font ${index === selectedProduct ? '' : 'color-gray'}`} >
                            {res.name}
                        </p>
                    </div>
                ))}
            </div>
            <Customize
                category={categoryUpdate}
                selectedProduct={selectedProduct}
            />
        </>
    );
};

export default Inspirations;