import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Calculator } from "../../../components/Calculator";
import { memoryForm } from "../../../content";
import { addToProjectAtom, addToProjectImageAtom } from "../../../state";
import AccessLinkPage from "./AccessLinkPage";
import SaveProjectModal from "./SaveProjectModal";

const OrderSummery = () => {
    const [modalShow, setModalShow] = useState({
        modalOne: false,
        modalTwo: false
    });
    const [addToProject, setAddToProject] = useRecoilState(addToProjectAtom);
    const [uniqueCode, setUniqueCode] = useState("");
    const [addToProjectImage, setAddToProjectImage] = useRecoilState(addToProjectImageAtom);
    const pillowQuentityHandler = (index, quen) => {
        if (quen) {
            const updateData = addToProject?.map((data, i) => i === index ? { ...data, quantity: quen } : data)
            setAddToProject(updateData);
        }
    }

    // remove product
    const removeProduct = (index) => {
        const updateData = addToProject.filter((data, i) => index !== i)
        const updateImage = addToProjectImage.filter((data, i) => index !== i)
        setAddToProject(updateData);
        setAddToProjectImage(updateImage);
    }

    const upholsteryRender = (summary, index) => {
        const { platinumSeat } = summary?.upholsteryItem
        const quen = summary?.quantity
        const memoryName = memoryForm[summary?.fabric?.category]
        const priceSum = quen * (summary?.fabric?.price + (platinumSeat === "Yes" ? memoryName?.cost : 0))
        const skuName = summary?.fabric?.optionSku?.[summary?.legSelection] || summary?.fabric?.sku
        return <div className="container text-center p-0">
            <div className="container p-0">
                <div className="cart pb-4 border-bottom"
                    key={`${summary?.name} ${index}`}
                >
                    <div className="row">
                        <div className="col-12 col-sm-4 col-md-6 col-lg-4">
                            <div className="cart-item d-flex align-items-center justify-content-start">
                                <div>
                                    <img src={summary?.base64Image} className="img-fluid" alt="badge-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-5 col-md-3 col-lg-5 d-flex text-start pt-4">
                            <div>
                                <p className="m-0">Name: {summary?.name}</p>
                                {skuName && <p className="m-0 color-gray">SKU: {skuName}</p>}
                                {summary?.paintName?.Fabric && <p className="m-0 color-gray">Fabric: {summary?.paintName?.Fabric}</p>}
                                {summary?.paintName?.Inner && <p className="m-0 color-gray">Inside Fabric: {summary?.paintName?.Inner}</p>}
                                {summary?.paintName?.Outer && <p className="m-0 color-gray">Outside Fabric: {summary?.paintName?.Outer}</p>}
                                {summary?.paintName?.Pillow && <p className="m-0 color-gray">Pillow: {summary?.paintName?.Pillow}</p>}
                                {summary?.paintName?.Legs && <p className="m-0 color-gray">Legs ({summary?.legSelection}): {summary?.paintName?.Legs}</p>}
                                <p className="m-0 color-gray">Memory Form: {platinumSeat === "Yes" ? memoryName?.optionCode : 'Standard'}</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-3 col-md-3 col-lg-3 pt-4 d-flex justify-content-between">
                            <div className="qty-rmv ">
                                <div className="quantity " style={{ userSelect: 'none' }}>
                                    <p className="quantity__minus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity - 1)}
                                    >
                                        <span>-</span>
                                    </p>
                                    <input
                                        name="quantity"
                                        type="text"
                                        className="quantity__input"
                                        value={summary?.quantity}
                                        minValue={1}
                                        onChange={(e) => pillowQuentityHandler(index, e.target.value > 0 ? Number(e.target.value) : 1)}
                                    />
                                    <p className="quantity__plus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity + 1)}
                                    >
                                        <span>+</span>
                                    </p>
                                </div>
                            </div>
                            <div className="price text-right" style={{ marginTop: 10 }}>
                                <p className="multi-qty-price price-text mb-0">${priceSum?.toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    console.log("addToProject", addToProject)

    return (
        <>
            {addToProject?.length ?
                <div className="container text-center">
                    <div className="container">
                        <h2 className="title">Project Summary</h2>
                        <h5 className="sub-title mb-5">
                            You will need these products for this project
                        </h5>
                        {addToProject?.map((summary, index) => {
                            return <div key={`${summary?.name} ${index}`} className='mt-3'>
                                <div className=" d-flex justify-content-between fw-400 ">
                                    <div className="fs-20">
                                        {summary?.seriesName} {summary?.name}
                                    </div>
                                    <div className="fs-16 text-red d-flex align-items-center cursor-pointer m-0" onClick={() => removeProduct(index)}>
                                        Remove
                                    </div>
                                </div>
                                <div className="border-gray mt-3"></div>
                                {upholsteryRender(summary, index)}
                            </div>
                        })}
                        <div className="d-flex align-items-center justify-content-md-end justify-content-center btn-mutiple flex-wrap">
                            <button
                                className="btn btn-outline-primary mb-3"
                                onClick={() => setModalShow({
                                    ...modalShow,
                                    modalOne: true
                                })}
                            >
                                Save Project
                            </button>
                        </div>
                    </div>
                </div>
                : <></>
            }

            {modalShow?.modalOne && <SaveProjectModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                setUniqueCode={setUniqueCode}
            />
            }
            {modalShow?.modalTwo && <AccessLinkPage
                modalShow={modalShow}
                setModalShow={setModalShow}
                setUniqueCode={setUniqueCode}
                uniqueCode={uniqueCode}
            />
            }

        </>
    )

}

export default OrderSummery;