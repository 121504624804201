import React from "react";

const Footer = () => {
    return (
        <section className="footer section text-center">
            <div className="copyright">
                <span className="copy">
                    Copyright
                    <span className="copyright--shop_name">
                        <a href="/" title="">Lazar</a>
                        2023.
                    </span>
                </span>
            </div>
        </section>
    )
}

export default Footer;