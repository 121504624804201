const content = {
  Category: [
    {
      "name": "Kinetic",
      "image": "lazar_product_list/Kinetic_Sofa/364020C_thumb.jpeg",
      "router": "kinetic"
    },
    // {
    //   "name": "Manhattan",
    //   "image": "lazar_product_list/Manhattan_Sofa/144120C_thumb.jpeg",
    //   "router": "manhattan"
    // },
    {
      "name": "Belize",
      "image": "lazar_product_list/Belize_Sofa/507220_thumb.jpeg",
      "router": "belize"
    },
    // {
    //   "name": "Gina Curved",
    //   "image": "lazar_product_list/Gina_Curved_Sofa/145543_thumb.jpeg",
    //   "router": "gina-curved"
    // },
    {
      "name": "Jeremiah",
      "image": "lazar_product_list/Jeremiah/1416_thumb.jpeg",
      "router": "jeremiah"
    },
    {
      "name": "Grove",
      "image": "lazar_product_list/Grove/Grove_thumb.jpeg",
      "router": "grove"
    },
    {
      "name": "Jonathan",
      "image": "lazar_product_list/Jonathan/Jonathan_thumb.jpeg",
      "router": "jonathan"
    },
    {
      "name": "Nathan",
      "image": "lazar_product_list/Nathan/Nathan_thumb.jpeg",
      "router": "nathan"
    },
    {
      "name": "Knox",
      "image": "lazar_product_list/Knox/Knox_thumb.jpeg",
      "router": "knox"
    },
    {
      "name": "Key West",
      "image": "lazar_product_list/Key_West/Key_West_thumb.jpeg",
      "router": "keyWest"
    },
    {
      "name": "Jensen",
      "image": "lazar_product_list/Jensen/Jensent_thumb.png",
      "router": "jensen"
    },
    {
      "name": "Langdon",
      "image": "lazar_product_list/Langdon/Langdont_thumb.png",
      "router": "langdon"
    },
    {
      "name": "Garrett",
      "image": "lazar_product_list/Garrett/Garrettt_thumb.png",
      "router": "garrett"
    },
    {
      "name": "Bali",
      "image": "lazar_product_list/Bali/Bali.png",
      "router": "bali"
    },
    {
      "name": "Bohemia",
      "image": "lazar_product_list/Bohemia/Bohemia.png",
      "router": "bohemia"
    },

  ],
  "StoutCategory": [
    {
      "name": "Caracole Arm Chair",
      "image": "Stout Thumb/Caracole_Fair_and_Square_Armchair.png",
      "router": "caracole"
    },
    {
      "name": "Passadena",
      "image": "Stout Thumb/Passadena_Chair.png",
      "router": "passadena"
    },
    {
      "name": "Slip Cover Chair",
      "image": "Stout Thumb/Slip_Cover_Chair.png",
      "router": "slip"
    },
    {
      "name": "Willowbrook Chair",
      "image": "Stout Thumb/WILLOWBROOK_CHAIR.png",
      "router": "willowbrook"
    },
    {
      "name": "York Slope",
      "image": "Stout Thumb/York_Slope_Armchair.png",
      "router": "york"
    },
  ],

  inspirations: {
    kinetic: [
      {
        name: "Kinetic",
        image: "lazar_product_list/Kinetic_Sofa/364020_SOFA.png",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/4570a76c-ecca-4959-9a17-87a4f695ee1e/3b58662c-fe9e-4e4c-b344-32ea0297c7f7.gltf",
        sku: "364020",
        category: "Sofa",
        options: ['Wood', 'Metal'],
        price: 5543,
        optionSku: {
          Wood: '364020',
          Metal: '364020C',
        },
      },
      {
        name: "Left Bumper Right Arm",
        image: "lazar_product_list/Kinetic_Sofa/364020_SOFA_1.png",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/00fa9d74-dbe7-4e04-b19f-5f592cf5dbe0/ce8d4cde-0014-4ae1-9f00-e0f8289fb239.gltf",
        sku: "364020",
        category: "Sofa",
        options: ['Wood', 'Metal'],
        price: 5543,
        optionSku: {
          Wood: '364020',
          Metal: '364020C',
        },
      },
      {
        name: "Full Kinetic Condo Sofa",
        image: "lazar_product_list/Kinetic_Sofa/364020_SOFA_2.png",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/2e4596bd-7e7a-4a6a-bc66-9f16fed2e8ba/713c2244-4e66-4c13-a864-8f336601f7ac.gltf",
        sku: "OD364051",
        category: "Sofa",
        price: 5047,
        options: ['Wood']
      }
    ],
    // manhattan: [
    //   {
    //     name: "Manhattan",
    //     series: "",
    //     description:
    //       "Sofa In BELLISIMO WHITE, 22x22 Knife Edge Toss Pillows In Taos Piano (Not Standard)",
    //     dimensions: "105W 31H 36D 18SH 22SD 26AH",
    //     designedBy: "Stanley Jay Friedman",
    //     sku: "144120C",
    //     image: "lazar_product_list/Manhattan_Sofa/144120C_thumb.jpeg",
    //     category: "sofa",
    //     Options: ["Metal_Swivel_Base_Options", "Memory_Foam"],
    //     modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/342734c3-69ea-4148-832f-58d9a4d0a1c5/81268c8a-cfde-4f59-bef5-805a076533ba.gltf",
    //   },
    // ],
    belize: [
      {
        name: "Belize",
        series: "",
        description: "Platinum Collection",
        dimensions: "94 Width X 34.5 Height X 39.5 Depth, Arm Height 26.5, Seat Height 18, Seat Width 76, Seat Depth 21",
        designedBy: "JOHN MASCHERONI",
        sku: "703420",
        image: "lazar_product_list/Belize_Sofa/703420.png",
        category: "Sofa",
        options: ['Wood'],
        price: 5047,
        pillowShow: true,
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/d3f94abe-8928-4fcb-a72f-64d578f1001d/91755e4b-93ac-4a9f-8a37-0df8201dc4d6.gltf",
      },
      {
        name: "Armless chair",
        series: "",
        description: "Platinum Collection",
        dimensions: "94 Width X 34.5 Height X 39.5 Depth, Arm Height 26.5, Seat Height 18, Seat Width 76, Seat Depth 21",
        designedBy: "JOHN MASCHERONI",
        sku: "703402SW",
        image: "lazar_product_list/Belize_Sofa/703402SW.png",
        category: "Swivel",
        options: ['Wood'],
        price: 5047,
        pillowShow: false,
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/41d8b9b4-a5f3-48a5-a15d-9c95b4baff4a/32a4aa41-01e1-44dc-b448-89baaeae4789.gltf",
      },
      // {
      //   name: "Belize Armless Swivel Chair",
      //   series: "",
      //   description: "Platinum Collection",
      //   dimensions: "94 Width X 34.5 Height X 39.5 Depth, Arm Height 26.5, Seat Height 18, Seat Width 76, Seat Depth 21",
      //   designedBy: "JOHN MASCHERONI",
      //   sku: "703402SW",
      //   image: "lazar_product_list/Belize_Sofa/703402SW.png",
      //   category: "Swivel",
      //   options: ['Wood'],
      //   price: 5047,
      //   modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/0c48db20-9e0c-425c-980e-5c4cf18cf14f/362eb322-01ac-4cc0-b254-83e7f9d17b08.gltf",
      // },
      // {
      //   name: "Belize LAF",
      //   series: "",
      //   description: "Platinum Collection",
      //   dimensions: "94 Width X 34.5 Height X 39.5 Depth, Arm Height 26.5, Seat Height 18, Seat Width 76, Seat Depth 21",
      //   designedBy: "JOHN MASCHERONI",
      //   sku: "703417L",
      //   image: "lazar_product_list/Belize_Sofa/703417L.png",
      //   category: "Sofa",
      //   options: ['Wood'],
      //   price: 5047,
      //   modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/0c48db20-9e0c-425c-980e-5c4cf18cf14f/362eb322-01ac-4cc0-b254-83e7f9d17b08.gltf",
      // },
      // {
      //   name: "Belize RAF",
      //   series: "",
      //   description: "Platinum Collection",
      //   dimensions: "94 Width X 34.5 Height X 39.5 Depth, Arm Height 26.5, Seat Height 18, Seat Width 76, Seat Depth 21",
      //   designedBy: "JOHN MASCHERONI",
      //   sku: "703417R",
      //   image: "lazar_product_list/Belize_Sofa/703417R.png",
      //   category: "Sofa",
      //   options: ['Wood'],
      //   price: 5047,
      //   modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/0c48db20-9e0c-425c-980e-5c4cf18cf14f/362eb322-01ac-4cc0-b254-83e7f9d17b08.gltf",
      // },
    ],
    // 'gina-curved': [
    //   {
    //     name: "Gina Curved",
    //     series: "",
    //     description:
    //       "Curved Sofa In Britain Ivory W/Espresso Finish (Toss Pillows Not Standard) 22x22 Knife Edge Pillows In Prospect Platinum",
    //     dimensions: "92W X 28H X 39D",
    //     designedBy: "John Mascheroni",
    //     sku: "145543",
    //     image: "lazar_product_list/Gina_Curved_Sofa/145543_thumb.jpeg",
    //     category: "sofa",
    //     modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/342734c3-69ea-4148-832f-58d9a4d0a1c5/81268c8a-cfde-4f59-bef5-805a076533ba.gltf",
    //   },
    // ],
    jeremiah: [
      // {
      //   name: "Jeremiah Large 2pc",
      //   series: "JEREMIAH 1416",
      //   description:
      //     "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
      //   dimensions:
      //     "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
      //   designedBy: "",
      //   sku: "141699",
      //   price: 5988,
      //   image: 'lazar_product_list/Jeremiah/141699.png',
      //   options: ['Wood'],
      //   modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/2616ff6c-f943-4055-966f-489aa5bed13b/2ded16b8-f1e1-4b61-9732-b2e8c30424de.gltf",
      // },
      {
        name: "Jeremiah Armless 2pc",
        series: "JEREMIAH 1416",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141614",
        price: 4933,
        image: 'lazar_product_list/Jeremiah/141614.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/d3d0b8ed-6e07-471f-9a63-146c8f30bfa0/9f9f6a5e-da7e-4ac5-af4f-23645eb97119.gltf",
        pillowShow: false,
      },
      {
        name: "Jeremiah 2pc",
        series: "JEREMIAH 1416",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141620",
        price: 5597,
        image: 'lazar_product_list/Jeremiah/141620.png',
        options: ['Wood'],
        pillowShow: true,
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/d7bb2a11-44bc-41de-87a9-946463b7a559/84bda52f-d85b-42df-9173-ae251907c456.gltf",
      },
      {
        name: "One arm condo",
        series: "JEREMIAH 1416",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141650",
        price: 4871,
        image: 'lazar_product_list/Jeremiah/141650.png',
        options: ['Wood'],
        pillowShow: true,
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/45ab5184-49d8-4703-a5a4-d753822b7474/ed0174d1-0099-4f06-a9d0-c29ed18103d1.gltf",
      },
      // {
      //   name: "Armless condo",
      //   series: "JEREMIAH 1416",
      //   description:
      //     "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
      //   dimensions:
      //     "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
      //   designedBy: "",
      //   sku: "141649",
      //   price: 4519,
      //   image: 'lazar_product_list/Jeremiah/141649.png',
      //   options: ['Wood'],
      // category: "Sofa",
      //   modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/4f163147-e6bf-4ee3-b54e-18834fb4f12d/5e56a35c-897b-4d8d-94ae-dc6d5e80c5c7.gltf",
      // },
      // {
      //   name: "Sofa",
      //   series: "JEREMIAH 1416",
      //   description:
      //     "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
      //   dimensions:
      //     "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
      //   designedBy: "",
      //   sku: "141620",
      //   price: 5597,
      //   image: 'lazar_product_list/Jeremiah/141620.png',
      //   options: ['Wood'],
      // category: "Sofa",
      //   modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/21333bc1-b1c2-4703-85b3-07aefd1d527d/57366676-8d25-492d-a437-4be90fd79dd7.gltf",
      // },
      {
        name: "One arm sofa",
        series: "JEREMIAH 1416",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141618",
        price: 5597,
        image: 'lazar_product_list/Jeremiah/141618.png',
        options: ['Wood'],
        pillowShow: true,
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/5a184721-d75a-4820-b0d0-e053ceaa5976/9a5cd1aa-3beb-41ed-be45-76c42e922663.gltf",
      },
      {
        name: "one arm",
        series: "JEREMIAH 1416",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141617",
        price: 5265,
        image: 'lazar_product_list/Jeremiah/141617.png',
        options: ['Wood'],
        pillowShow: true,
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/b268674d-71e8-43d2-80d6-6d9fd5b0e6b0/b09bf3d3-eb6c-4d28-aa76-766c631f05cd.gltf",
      },
      // {
      //   name: "ARMLESS SOFA",
      //   series: "JEREMIAH 1416",
      //   description:
      //     "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
      //   dimensions:
      //     "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
      //   designedBy: "",
      //   sku: "141614",
      //   price: 5988,
      //   image: 'lazar_product_list/Jeremiah/141614.png',
      //   options: ['Wood'],
      //   category: "Sofa",
      //   modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/d3d0b8ed-6e07-471f-9a63-146c8f30bfa0/9f9f6a5e-da7e-4ac5-af4f-23645eb97119.gltf",
      // },
      {
        name: "One arm loveseat",
        series: "JEREMIAH 1416",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141611",
        price: 4436,
        image: 'lazar_product_list/Jeremiah/141611.jpg',
        options: ['Wood'],
        pillowShow: true,
        category: "Loveseat",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/89463f30-336d-4900-b091-bb21a5003ba7/d6de3661-1fea-4023-b879-8a27010daf00.gltf",
      },
      {
        name: "One arm chaise",
        series: "JEREMIAH 1416",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141607",
        price: 4560,
        image: 'lazar_product_list/Jeremiah/141607.jpg',
        options: ['Wood'],
        pillowShow: true,
        category: "Chaise",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/12f80f05-be79-414e-923e-f1989309628f/5a3e54ff-c426-4d74-8eb1-0693dcdac9a2.gltf",
      },
      {
        name: "One arm angle",
        series: "JEREMIAH 1416",
        description:
          "1 Arm angle (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF angle: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141661",
        price: 5182,
        image: 'lazar_product_list/Jeremiah/141661.png',
        options: ['Wood'],
        pillowShow: true,
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/88017e01-0412-4863-9511-a79664888607/841572f1-3eea-4383-bd27-691ead912613.gltf",
      },
      {
        name: "One arm loveseat",
        series: "JEREMIAH 1416",
        description:
          "1 Arm loveseat (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF loveseat: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141610",
        price: 4436,
        image: 'lazar_product_list/Jeremiah/141610.png',
        options: ['Wood'],
        pillowShow: true,
        category: "Loveseat",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/8fbfee23-6e8d-4b1d-9cd9-aae4472a7fac/907a1462-d1b1-4186-b770-d645b1fe398a.gltf",
      },
      {
        name: "Corner chair",
        series: "JEREMIAH 1416",
        description:
          "Corner chair (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "Corner chair: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141605",
        price: 3109,
        image: 'lazar_product_list/Jeremiah/141605.png',
        options: ['Wood'],
        pillowShow: true,
        category: "Chair",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/604cba6d-4b24-417a-b292-a760967828c2/0be68995-19aa-49ba-a916-acc5d9a7b30f.gltf",
      },
      {
        name: "One arm chair",
        series: "JEREMIAH 1416",
        description:
          "1 Arm chair (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF chair: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141603",
        price: 3109,
        image: 'lazar_product_list/Jeremiah/141603.png',
        options: ['Wood'],
        pillowShow: true,
        category: "Chair",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/4c60282e-6624-49f1-b515-0ad1f5f1d5c3/f86138d7-2d37-4bdb-a9cd-2f2633e45acb.gltf",
      },
      // {
      //   name: "Armless chair",
      //   series: "JEREMIAH 1416",
      //   description:
      //     "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
      //   dimensions:
      //     "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
      //   designedBy: "",
      //   sku: "141602",
      //   price: 2542,
      //   image: 'lazar_product_list/Jeremiah/141602.jpg',
      //   options: ['Wood'],
      //   modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/46318c5f-5f87-49e9-92e3-5269e04de05f/9d4815f7-eb8e-4ac8-9a55-a340d1d2313a.gltf",
      // },
      // {
      //   name: "Ottoman",
      //   series: "JEREMIAH 1416",
      //   description:
      //     "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
      //   dimensions:
      //     "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
      //   designedBy: "",
      //   sku: "141601",
      //   price: 3463,
      //   image: 'lazar_product_list/Jeremiah/141601.jpg',
      //   options: ['Wood'],
      //   modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/0805fee8-4491-4b4f-a09d-10b006655006/fd7c0942-aaf6-4a06-90ae-ab319e47052c.gltf",
      // },
    ],
    grove: [
      {
        name: "Grove Swivel Chair",
        series: "2818 GROVE SERIES",
        description:
          "Swivel Chair In Bellisimo Grey W/25 Stainless Steel Swivel Base",
        dimensions: "31W, 33H, 33D, 60lbs",
        designedBy: "Michael Wolk",
        sku: "2818SWW",
        image: 'lazar_product_list/Grove/2818SWW.png',
        category: "Swivel",
        options: ['Metal', 'Upholstered'],
        optionSku: {
          Wood: '2818SWW',
          Metal: '2818CSW',
          Upholstered: '2818SWU',
        },
        optionPrice: {
          Wood: 2728,
          Metal: 3024,
          Upholstered: 2467,
        },
        price: 2728,
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/8e7e2397-fb63-4ba1-b5d5-a0f36501c7f0/a917b91a-00c9-4286-aeb6-a0bf43633b96.gltf",
      },
      {
        name: "Grove RAF Chaise",
        series: "2818 GROVE SERIES",
        description:
          "Swivel Chair In Bellisimo Grey W/25 Stainless Steel Swivel Base",
        dimensions: "",
        designedBy: "Michael Wolk",
        sku: "281807R",
        image: 'lazar_product_list/Grove/281807R.png',
        category: "Chaise",
        options: ['Metal', 'Upholstered'],
        price: 2819,
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/bd515985-c470-4357-9990-15b5800048a2/8437200e-f20b-4a1f-b765-c5daa24096d4.gltf",
      },
      {
        name: "Grove LAF Chaise",
        series: "2818 GROVE SERIES",
        description:
          "Swivel Chair In Bellisimo Grey W/25 Stainless Steel Swivel Base",
        dimensions: "",
        designedBy: "Michael Wolk",
        sku: "281807L",
        image: 'lazar_product_list/Grove/281807L.png',
        category: "Chaise",
        options: ['Metal', 'Upholstered'],
        price: 2819,
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/e558f9eb-3103-4631-8877-00da455ca525/a7fbed36-aee7-4e33-97dd-5dfa1d273f85.gltf",
      }
    ],
    jonathan: [
      {
        name: "Jonathan",
        series: "Jonathan 280805",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280805",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280805.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/9ae3bf33-2eeb-4fe0-9aa8-2259cdf6f89a/d6e257c4-d2f7-4cc6-b818-a234689457fa.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280820",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280820",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280820.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/8b8f68e3-28a9-4884-ab36-14988e656511/dba1be68-7a06-42b4-b5dc-5cedc2a1ec64.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280802",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280802",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280802.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/ce43d921-709e-46c4-ade7-0442193b3a64/af941eca-417b-4032-bf66-1a0eb398c2db.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280807L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280807L",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280807L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/543a5e15-f3a1-4e6b-9e91-340f9b1099f7/03659f80-59b7-4fe8-a9b1-10c2accfd02b.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280815L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280815L",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280815L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/793ccf85-a130-4323-b11a-3af43f0610f2/478e06e6-87cb-4701-9a01-4e7f9e3f7a59.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280817L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280817L",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280817L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/5042f67a-a707-47b8-aaf7-ecd5b434a1b5/b4bfac49-8c98-4633-98ba-417b53700b07.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280804",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280804",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280804.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/699436c6-de0c-434f-9b91-be1bfc597766/32e2fdc6-8b6e-48e3-a06d-dcd64a599766.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280818R",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280818R",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280818R.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/28c5a247-93da-40f9-8e3f-79f11d5a6941/cc62956d-ce6a-4ae9-b1b0-824fac7cae89.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280803L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280803L",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280803L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/cf890cb2-8fe1-43e5-991e-ce9211127132/55f057dc-a48e-4277-a671-c213eca041b7.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280801",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280801",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280801.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/bf03dac9-1781-428c-a437-fde8f0a9a292/7e7e1f9c-ab40-42f7-87b2-ff5875ea9825.gltf",
        pillowShow: false,
      },
      {
        name: "Jonathan",
        series: "Jonathan 280810L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280810L",
        price: 4933,
        image: 'lazar_product_list/Jonathan/280810L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/7877e260-ef72-4fb7-9f78-29f0758bd511/d9d31f4c-80f6-496b-a2b4-508291763325.gltf",
        pillowShow: false,
      },
    ],
    knox: [
      {
        name: "Knox",
        series: "Knox 280805",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280805",
        price: 4933,
        image: 'lazar_product_list/Knox/280805.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/ed3f4007-bb78-4d10-9d6d-feef12568286/4cef8029-6458-4304-a9c5-533d127ecc35.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280820",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280820",
        price: 4933,
        image: 'lazar_product_list/Knox/280820.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/5437b7c7-d0dc-4924-81d2-557c291b922a/323610f4-406e-4a38-a8ae-f285f413c4da.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280802",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280802",
        price: 4933,
        image: 'lazar_product_list/Knox/280802.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/6d07cdc1-904b-48b1-9035-cddb4b3a3b7f/211f651a-e109-40d3-81cb-e39481f24b54.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280807L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280807L",
        price: 4933,
        image: 'lazar_product_list/Knox/280807L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/f0e60d12-354f-4b4b-aac7-c6a04b4a85b7/c092a0d9-bf91-4ee1-9acc-e4ab98e84e3e.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280815L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280815L",
        price: 4933,
        image: 'lazar_product_list/Knox/280815L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/cf90c914-7822-4809-ad4f-e237963192b8/ac1fa2c3-038c-400c-96b1-52968d7d22dd.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280817L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280817L",
        price: 4933,
        image: 'lazar_product_list/Knox/280817L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/6fbc41d1-4e2f-4b57-8081-dbcac07f2c47/fc937465-1620-47e0-8e68-f5e68003420a.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280804",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280804",
        price: 4933,
        image: 'lazar_product_list/Knox/280804.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/04d1bee8-582a-43fe-88ca-bd4cad4046b7/75288a42-366b-47f6-9830-97d4bb4537a1.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280818R",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280818R",
        price: 4933,
        image: 'lazar_product_list/Knox/280818R.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/446425cf-6f8a-4009-a12d-7ef84f018577/d96d04e0-ff61-4f18-b8fe-5c2322e7b504.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280803L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280803L",
        price: 4933,
        image: 'lazar_product_list/Knox/280803L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/ecf62d70-e214-45ed-bfa1-facb2b1a717b/027dee4a-121b-4173-afbd-1dc624398347.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280801",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280801",
        price: 4933,
        image: 'lazar_product_list/Knox/280801.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/a789fe69-fa50-4ea2-b9f9-ffe64d692b73/572b934a-d842-42d4-a952-5fb5c8e31a1d.gltf",
        pillowShow: false,
      },
      {
        name: "Knox",
        series: "Knox 280810L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "280810L",
        price: 4933,
        image: 'lazar_product_list/Knox/280810L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/49c6a683-722f-4f13-a6fd-1751f59bc9bd/2bb1a6b6-a464-4118-b827-d8faa749aa14.gltf",
        pillowShow: false,
      },
    ],
    keyWest: [
      {
        name: "Key west",
        series: "Key west 160305",
        sku: "160305",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/0defab14-9353-4b3a-bd48-a9c8a3387537/77d70454-dd67-4f5b-ae12-f163fdda5b86.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        image: 'lazar_product_list/Key_West/160305.png',
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 160315R",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "160315R",
        price: 4933,
        image: 'lazar_product_list/Key_West/160315R.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/10d05a59-e31e-4911-848b-b969aa568cad/990dcab6-bf19-432c-8f76-a2979668108c.gltf",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 160315L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "160315L",
        price: 4933,
        image: 'lazar_product_list/Key_West/160315L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/d0178a17-a9b0-4612-88ac-871401dae4b6/74c919ba-5c31-46e0-a2e9-92180a246ff6.gltf",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 160318L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "160318L",
        price: 4933,
        image: 'lazar_product_list/Key_West/160318R_OR_L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/2a6b7f2a-81e0-4588-8eae-0bb13d8385c2/ba2a90aa-6d24-4e10-8fe8-f8871b003543.gltf",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 160320",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "160320",
        price: 4933,
        image: 'lazar_product_list/Key_West/160320.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/11c91c44-5905-40a6-9c4f-0d770dbe03c3/e80a6134-ffb2-40ec-b9dc-b7dbee688942.gltf",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 160317L OR R",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "160317L OR R",
        price: 4933,
        image: 'lazar_product_list/Key_West/160317L_OR_R.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/6a53db6d-f3f9-4b1d-9fc1-4364344f4b67/b9deab25-f216-4718-8faf-61b0651b1c7e.gltf",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 160303L OR R",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "160303L OR R",
        price: 4933,
        image: 'lazar_product_list/Key_West/160303L_OR_R.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/424dc429-f058-4832-92df-dc7310c135d3/86de770d-48b2-42e4-a7a2-a766b871655d.gltf",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 160302",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "160302",
        price: 4933,
        image: 'lazar_product_list/Key_West/160302.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/b4c877bc-cc65-4208-9139-6fdccfa9e7af/d9981f4b-5128-41b6-a528-e2d1a82b188e.gltf",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 160310L OR R",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "160310L OR R",
        price: 4933,
        image: 'lazar_product_list/Key_West/160310L_OR_R.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/a16c3296-103e-4ae8-a59e-b97732a64b8d/1430085f-7292-4417-be12-33b9498c5810.gltf",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 160307R",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "160307R",
        price: 4933,
        image: 'lazar_product_list/Key_West/160307R.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/a9c45d8f-c41f-4b98-93d1-e4b7b0a5b006/eebfffca-48b3-4bf2-acf0-5a453911222d.gltf",
        pillowShow: false,
      },
      {
        name: "Key west",
        series: "Key west 1603",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "1603",
        price: 4933,
        image: 'lazar_product_list/Key_West/1603.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/ec2b3137-96cf-42a9-8f14-70f1e2e9cd96/39146193-6da9-44b7-bd32-2bfae5f98a3d.gltf",
        pillowShow: false,
      },
    ],
    nathan: [
      {
        name: "Nathan",
        series: "Nathan 180805",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180805",
        price: 4933,
        image: 'lazar_product_list/Nathan/180805.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/e0483bd7-a5d0-4072-a80d-2b6ab360406f/780944cb-0f46-4ea3-8d26-0c0579147765.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180820",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180820",
        price: 4933,
        image: 'lazar_product_list/Nathan/180820.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/c68c48a2-53dd-43ae-bf97-cc1b340cb182/3a509f8f-beca-449d-8e76-9bd3493d8ea1.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180802",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180802",
        price: 4933,
        image: 'lazar_product_list/Nathan/180802.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/e16af66e-694c-425c-bf2a-dabf7a8f500e/aed6cb9c-3d83-4356-b09e-f174b7ca66ae.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 1808133L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "1808133L",
        price: 4933,
        image: 'lazar_product_list/Nathan/1808133L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/ab7eba61-5daa-4588-8088-bde12c96bffe/76399514-4496-452c-9938-9dfbb96caa89.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180804",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180804",
        price: 4933,
        image: 'lazar_product_list/Nathan/180804.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/5c6437c5-7483-40ef-b7ed-c010bbe94c59/1b8745f4-9e7f-4dd9-a692-451a8e8d5bf9.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180815L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180815L",
        price: 4933,
        image: 'lazar_product_list/Nathan/180815L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/e721a7dd-c3f8-49f2-9141-ff994925e642/dd7704d8-5a55-46ba-81d7-6753bc4a5b34.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180817L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180817L",
        price: 4933,
        image: 'lazar_product_list/Nathan/180817L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/86738750-f0b0-41b1-a1a4-87cac4980ba7/5b0f318a-69aa-4eb6-8c07-f92819945f95.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180813",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180813",
        price: 4933,
        image: 'lazar_product_list/Nathan/180813.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/11927773-7a48-4892-baca-9f83b450c9ea/5ce724fb-eefb-4789-bb5e-98bca1be7bd3.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180818R",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180818R",
        price: 4933,
        image: 'lazar_product_list/Nathan/180818R.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/d1658450-14be-46f9-8ac7-c1a19bbbeb61/5b9a861b-a11f-4377-94ba-7fd6cdc42156.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180803L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180803L",
        price: 4933,
        image: 'lazar_product_list/Nathan/180803L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/3aafe902-49f8-4020-8bd3-eddda77c0dd0/20c6db1c-c5dd-43d8-a718-f337276dae9a.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180814",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180814",
        price: 4933,
        image: 'lazar_product_list/Nathan/180814.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/907c4e13-1316-4221-ae01-a9622b84cf24/abc86925-4d07-4879-b0fd-515f838f98fa.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180810L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180810L",
        price: 4933,
        image: 'lazar_product_list/Nathan/180810L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/24ec6624-9738-4986-a7fd-ae9921f74805/56abe3a3-4e64-42ea-8adb-01cbc96c75d9.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180807L",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180807L",
        price: 4933,
        image: 'lazar_product_list/Nathan/180807L.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/fdb0a741-7f17-4656-9ac5-41dee3cb6e59/14b6b304-bb34-46e8-a32e-831df06ed1dd.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180801X",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180801X",
        price: 4933,
        image: 'lazar_product_list/Nathan/180801X.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/603a9708-7b15-4883-b9e5-0792de183b30/48b7b6cf-318f-445c-bf21-9319f39fc242.gltf",
        pillowShow: false,
      },
      {
        name: "Nathan",
        series: "Nathan 180801",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "180801",
        price: 4933,
        image: 'lazar_product_list/Nathan/180801.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/f26c6a91-bfe5-40d0-93de-96058f21c607/1fb0164b-ba8e-4bf5-a405-3da8f24ae205.gltf",
        pillowShow: false,
      },
    ],
    jensen: [
      {
        name: 'Jensen',
        series: "Jensen Sofa 154420",
        image: 'lazar_product_list/Jensen/154420.png',
        sku: '154420',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/9061eec6-9a8c-4c3e-826d-1871533b939e/5714ee74-2530-47a8-9cb4-792847509a68.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 154423",
        image: 'lazar_product_list/Jensen/154423.png',
        sku: '154423',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/0326e1ff-9f6a-4a40-a6b6-28fee45e169d/1eef10b5-96d7-4d74-aed4-e332a68d1482.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 154442L",
        image: 'lazar_product_list/Jensen/154442L.png',
        sku: '154442L',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/2a5f918a-2405-460a-828a-059f980e0680/cdc8b7ea-65df-4195-a240-314e2de2871d.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 154442R",
        image: 'lazar_product_list/Jensen/154442R.png',
        sku: '154442R',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/47195547-0071-478e-ac4d-bc7f1cf1704e/8530f3a1-965e-4cb6-88ac-ddf9120e8135.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 154452L",
        image: 'lazar_product_list/Jensen/154452L.png',
        sku: '154452L',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/58819086-5eda-41d9-9147-db12104cf20e/b8c8b91a-39a2-4269-b5ac-aa493d85e0c5.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 154461L",
        image: 'lazar_product_list/Jensen/154461L.png',
        sku: '154461L',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/57c01ac3-6885-4123-984f-9890cbfed77d/e8548f11-907a-4853-834b-0f983f718bf5.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 154461R",
        image: 'lazar_product_list/Jensen/154461R.png',
        sku: '154461R',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/b922f013-0d5d-4d48-b890-2c3b4ffb585c/f0b2d2dc-cc94-4a97-9dd1-5a3b4f221eea.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 154464L",
        image: 'lazar_product_list/Jensen/154464L.png',
        sku: '154464L',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/95f25278-8485-430d-8c41-b00eb57e3ede/c5f32c94-c262-4c09-a504-e02d062004f4.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 1544110L",
        image: 'lazar_product_list/Jensen/1544110L.png',
        sku: '1544110L',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/8fcdf3f8-2dd6-4b73-a313-4925c1f71b1a/b6dd28e0-3f89-4d5b-9d4b-2afc8ae2fcc9.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 1544110",
        image: 'lazar_product_list/Jensen/1544110R.png',
        sku: '1544110',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/41338c61-1c3a-4258-bf56-5e1ff51a7c17/3f3dcb0e-78bc-492f-af72-c76db6299681.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 1544129L",
        image: 'lazar_product_list/Jensen/1544129L.png',
        sku: '1544129L',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/d5afc3d8-6325-4402-a92a-2da643d7380b/7f709ad5-fa3d-44db-bc8e-9f7b19f26f1f.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 1544129R",
        image: 'lazar_product_list/Jensen/1544129R.png',
        sku: '1544129R',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/07ca9748-3ed7-49b4-bc3e-140353cecdb0/0b711b77-2d98-48c1-9385-cb5e3e2b0124.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 1544122",
        image: 'lazar_product_list/Jensen/1544122.png',
        sku: '1544122',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/0717abb6-1c04-4752-bb5b-03bd549f8e34/97e30ade-5787-43f5-b38c-cc5f6b538966.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 154464R",
        image: 'lazar_product_list/Jensen/154464R.png',
        sku: '154464R',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/9f9b6c6c-ac3b-4d12-963e-faf22e71a7bd/11cd0806-9517-4743-9dc6-e47fb5fbda70.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Jensen',
        series: "Jensen Sectional 154452R",
        image: 'lazar_product_list/Jensen/154452R.png',
        sku: '154452R',
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/cdb735ca-af69-495a-9d45-be476ee4ae47/f5f4ae03-ab51-470a-8653-a35289350fb8.gltf",
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
    ],
    langdon: [
      {
        name: "Langdon",
        series: "Langdon Plus 237510L",
        sku: "237510L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/f0d7a371-59f1-4a29-8d18-e66902eeca85/e190360d-0198-4da7-befe-9e0998cb257f.gltf",
        image: 'lazar_product_list/Langdon/237510L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },

      {
        name: "Langdon",
        series: "Langdon Plus 237509",
        sku: "237509",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/c775a0d9-ea25-4415-abf7-bfec395d2195/bdb0335e-e23a-4e02-bd1e-93ca7c279e52.gltf",
        image: 'lazar_product_list/Langdon/237509.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237550R",
        sku: "237550R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/2163693d-5fdc-4926-ae0a-ba9b5a80d041/8299d7a0-64f9-4154-b37d-7052232b0712.gltf",
        image: 'lazar_product_list/Langdon/237550R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237518R",
        sku: "237518R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/aa494a72-b873-4a68-b0a3-f8485cc49da7/3700b0bf-3718-43dc-9e2b-d8b3c50682c5.gltf",
        image: 'lazar_product_list/Langdon/237518R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237552L",
        sku: "237552L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/21be17da-53da-4055-abff-f2915e14750a/7fba18e5-0b71-4704-b008-32954c1e1d10.gltf",
        image: 'lazar_product_list/Langdon/237552L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "2375122 Langdon Plus",
        sku: "2375122",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/1622b8ba-60e9-45bd-9eeb-21a85d38edbb/3f6d2677-7b58-4183-b9e5-995c97be86f8.gltf",
        image: 'lazar_product_list/Langdon/2375122.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237507R",
        sku: "237507R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/1a9d309b-ba4e-47f4-b79a-7b5c1a951d05/605a76a7-7091-45d1-93ac-d61145f0ba27.gltf",
        image: 'lazar_product_list/Langdon/237507R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "237561R Langdon Plus",
        sku: "237561R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/38200a55-ab6e-4c11-ad42-f22466d4446e/e8898752-fb90-4e71-81fa-32d339dba006.gltf",
        image: 'lazar_product_list/Langdon/237561R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "237561L Langdon Plus",
        sku: "237561L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/c8e25128-29e3-4fa8-b884-2dccad863872/9d14885b-55b9-4ec8-b7fe-23093a648e5f.gltf",
        image: 'lazar_product_list/Langdon/237561L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 2375129R",
        sku: "2375129R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/15aa0403-1a7c-45b9-b492-82f3db7906d8/7e50bf07-462e-432e-944c-0a5715c1abc7.gltf",
        image: 'lazar_product_list/Langdon/2375129R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237564R",
        sku: "237564R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/44fdecfd-197d-462a-8155-62a8a48a1efc/f242e218-777e-4e0c-b52f-c99f49aabd03.gltf",
        image: 'lazar_product_list/Langdon/237564R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 2375110L",
        sku: "2375110L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/f48b13da-503c-4ba1-bbbd-587bdeafc754/dd5e8268-ce6d-4899-a18d-620f40f96788.gltf",
        image: 'lazar_product_list/Langdon/2375110L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 2375110R",
        sku: "2375110R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/562d3186-3cc0-4e13-a1eb-860329b3186a/244540e9-b1e0-4a23-8351-411a19976e4a.gltf",
        image: 'lazar_product_list/Langdon/2375110R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "237542R Langdon Plus",
        sku: "237542R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/826829a2-ac6d-4809-90ae-3e6e0c801224/1236665f-8716-4ce6-91f4-63e3bf780a12.gltf",
        image: 'lazar_product_list/Langdon/237542R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "237542L Langdon Plus",
        sku: "237542L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/45a811a1-4212-4490-b05f-a0a63e936807/c6a1d9b7-2559-4782-9874-9a96eddf7a2d.gltf",
        image: 'lazar_product_list/Langdon/237542L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "237523 Langdon Plus",
        sku: "237523",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/00cab423-489e-4571-abf3-63e0f26fd7ff/a247b557-9042-49c4-81a6-659067598132.gltf",
        image: 'lazar_product_list/Langdon/237523.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237564L",
        sku: "237564L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/a10e898f-4b93-4737-9f2b-7868e0e58027/7ba80ef9-218b-4b13-93f6-afa52ece8b36.gltf",
        image: 'lazar_product_list/Langdon/237564L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 2375129L",
        sku: "2375129L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/bb12dce8-2222-475c-8328-8230939f45b8/197f62aa-ab7d-4027-aeac-1da33169358d.gltf",
        image: 'lazar_product_list/Langdon/2375129L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },

      {
        name: "Langdon",
        series: "Langdon Plus 237507L",
        sku: "237507L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/558f41a3-d310-4ab3-bd01-6302e1bca393/3672fc3a-785b-4e95-8194-7247c66af5f9.gltf",
        image: 'lazar_product_list/Langdon/237507L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },

      {
        name: "Langdon",
        series: "Langdon Plus 237507RG",
        sku: "237507RG",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/81ea5af3-1913-45fd-9304-d8d67b9f0824/c3da901d-2b2d-47a8-80b6-b5c9026cedf2.gltf",
        image: 'lazar_product_list/Langdon/237507RG.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237507L",
        sku: "237507L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/fbd36a0a-b91c-4a3a-9b98-560536bfb1c5/ef89b355-2743-4d7e-9122-713a34db48ab.gltf",
        image: 'lazar_product_list/Langdon/237507L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237552R",
        sku: "237552R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/3ce60f90-507b-43ea-9966-865ebbcea36f/ddbaeb48-afd3-40d2-af92-6a3fd71d86df.gltf",
        image: 'lazar_product_list/Langdon/237552R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237502",
        sku: "237502",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/e100cd0b-cdc0-4737-9cd0-0bd0bffdef9b/eacbb8d8-6fb0-406c-822f-411d027a2431.gltf",
        image: 'lazar_product_list/Langdon/237502.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },

      {
        name: "Langdon",
        series: "Langdon Plus 237503R",
        sku: "237503R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/85b18113-5e96-4a40-9a51-347bc23166cd/e95e4638-e6fe-4990-b9af-283a6eb0ef39.gltf",
        image: 'lazar_product_list/Langdon/237503R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237503L",
        sku: "237503L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/46146460-a94d-4903-8726-d2a318458562/9685f91c-05b6-447b-9cf1-2d14c3bfec8f.gltf",
        image: 'lazar_product_list/Langdon/237503L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237505",
        sku: "237505",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/80e6580c-3373-469b-a40e-b11c169e0fba/8194de61-0e5b-4961-9865-0d3604c59489.gltf",
        image: 'lazar_product_list/Langdon/237505.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237504",
        sku: "237504",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/e34c435e-05b9-48b4-9178-04d1b7072530/2ea87dd8-e27f-4768-bf90-d226cfd72b20.gltf",
        image: 'lazar_product_list/Langdon/237504.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237518L",
        sku: "237518L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/31be3a6f-97f8-41af-b3ce-9851659561d0/6dc697b6-db10-4e80-8375-8646f41583a1.gltf",
        image: 'lazar_product_list/Langdon/237518L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237501",
        sku: "237501",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/dfcd885a-44b2-4742-afbc-f25757059413/73f0bed6-07fc-48c5-983d-d8c4302d8674.gltf",
        image: 'lazar_product_list/Langdon/237501.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237550L",
        sku: "237550L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/74ae652b-54ac-4199-937d-44d5785341b2/616b13a3-7d65-4e7f-b9cc-68b288865b71.gltf",
        image: 'lazar_product_list/Langdon/237550L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },

      {
        name: "Langdon",
        series: "Langdon Plus 237551",
        sku: "237551",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/65274621-6773-48cd-b789-2d54d1aa41a5/e2f856c7-5605-435f-9e93-1a7c10a98b71.gltf",
        image: 'lazar_product_list/Langdon/237551.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237510R",
        sku: "237510R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/8f3886a5-4da3-471c-a627-10263a69c272/f7cb0f38-448a-431f-9ef7-9e24348a70f1.gltf",
        image: 'lazar_product_list/Langdon/237510R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237513",
        sku: "237513",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/a11d6604-0d74-411b-bcef-eb747decc0e9/8efd6457-33eb-48ce-8db8-f86032ba0a97.gltf",
        image: 'lazar_product_list/Langdon/237513.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237514",
        sku: "237514",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/6f5f471e-2ffb-4222-a942-940012dccd05/4b01cfb3-a33d-43a8-9fd4-156f8416ae38.gltf",
        image: 'lazar_product_list/Langdon/237514.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237517R",
        sku: "237517R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/54883b98-4699-4ed6-8424-0aef2cf4d21e/7c7c4ef9-0b0d-45a9-9a9e-00ff001f3466.gltf",
        image: 'lazar_product_list/Langdon/237517R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237517L",
        sku: "237517L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/dd94da06-1774-4759-919a-676de465541f/06d35a4f-da93-46bd-90a8-0306b9c6937e.gltf",
        image: 'lazar_product_list/Langdon/237517L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: "Langdon",
        series: "Langdon Plus 237520",
        sku: "237520",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/336a3d8f-a5f5-4c21-8da7-7e8d982d5ee0/77d57b8c-2a63-483c-be5d-fe1f0468e447.gltf",
        image: 'lazar_product_list/Langdon/237520.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
    ],
    garrett: [
      {
        name: 'Garrentt',
        series: "GARRETT 150013",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/cee3a91e-875c-4f13-8092-dd3bdb602b6d/93c3cdf1-be2a-4e93-85ac-a1c42f2c55b2.gltf",
        sku: "150013",
        image: 'lazar_product_list/Garrett/150013.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT M150004",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/ef5a32c4-79d5-4dd9-a2e4-e4df543e0c50/9779e470-7e15-4500-8f68-56d46bd133d5.gltf",
        sku: "M150004",
        image: 'lazar_product_list/Garrett/M150004.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150005L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/14256674-2557-4340-b48e-c894f8d937c8/390635d3-15bd-47be-ad99-a74c53c77959.gltf",
        sku: "150005L",
        image: 'lazar_product_list/Garrett/150005L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150003L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/4bea8989-d1d1-49c1-b748-0bf2a9af8422/1ea91ab6-e97a-4de6-b0e3-2e5a42054460.gltf",
        sku: "150003L",
        image: 'lazar_product_list/Garrett/150003L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150003R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/fed7a7da-5c0e-4fa1-9cde-c7bc71e13577/1a730216-3663-400d-8389-60278aca2d72.gltf",
        sku: "150003R",
        image: 'lazar_product_list/Garrett/150003R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150010L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/e0d5d884-8e8e-449a-b805-d99d563b235d/9ebdc26c-6371-4d89-a024-f6dfc17b367c.gltf",
        sku: "150010L",
        image: 'lazar_product_list/Garrett/150010L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150010R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/6bc03f1f-7e87-4ad8-81aa-db062d33238a/4a239ff6-81e7-4710-966e-479e6ed39b5a.gltf",
        sku: "150010R",
        image: 'lazar_product_list/Garrett/150010R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150050R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/a46205de-100c-4ba8-92e0-2915fb9412e6/81a71795-f649-483f-8dbc-a203a63a899d.gltf",
        sku: "150050R",
        image: 'lazar_product_list/Garrett/150050R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150050L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/2a766f2b-5cea-4e33-b289-2da3a08b87f6/99288ce9-a3f2-46aa-84a5-11abf6af3447.gltf",
        sku: "150050L",
        image: 'lazar_product_list/Garrett/150050L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150051",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/2aa075f0-e065-442f-beed-8d088831e904/e75079fc-ab48-4bd7-9ce6-02510d2f3e6b.gltf",
        sku: "150051",
        image: 'lazar_product_list/Garrett/150051.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150001",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/8bb20f57-7193-4550-a0a2-4dffa103c982/f063e85d-bcd6-40ef-a877-5c2398ecd703.gltf",
        sku: "150001",
        image: 'lazar_product_list/Garrett/150001.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150009",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/c573ced8-1754-4d19-9ad2-3b8468b02a10/557a19c3-3542-499c-b0eb-071ef5d4cd61.gltf",
        sku: "150009",
        image: 'lazar_product_list/Garrett/150009.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150002",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/64b3aabb-210f-4372-8433-31311c90b6ed/95ddd692-5447-4b34-968b-1f737e9a5e7a.gltf",
        sku: "150002",
        image: 'lazar_product_list/Garrett/150002.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150011L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/b4b4a9c1-5503-4353-ae17-d7ea91369e02/19f18be0-1b65-410d-994c-952c7f603982.gltf",
        sku: "150011L",
        image: 'lazar_product_list/Garrett/150011L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150011R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/b8b95262-a376-4071-83a1-5ccf40007abf/eff773e6-1e81-450a-8c7a-c69f35bca44e.gltf",
        sku: "150011R",
        image: 'lazar_product_list/Garrett/150011R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150017L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/f7a77c80-f5a5-4843-b880-f205cf5d5743/dc7166ba-951b-4e9c-a84f-1f252cccd95d.gltf",
        sku: "150017L",
        image: 'lazar_product_list/Garrett/150017L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150017R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/1fbf5dc7-136c-41ba-9df6-885a4c7e0e38/efbed5f3-7258-4ceb-bc9b-cd4143e9441f.gltf",
        sku: "150017R",
        image: 'lazar_product_list/Garrett/150017R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150007R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/7816c512-3ab8-4ee7-84ed-db0c51cb0d75/8da31dcb-5671-446b-b8f7-fbb37b5cde53.gltf",
        sku: "150007R",
        image: 'lazar_product_list/Garrett/150007R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150020",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/c07b6869-0c08-465a-aec9-7cfa092d3211/2270e3f6-4d44-4975-bce5-4f6fffd75136.gltf",
        sku: "150020",
        image: 'lazar_product_list/Garrett/150020.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150014",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/796bbb9c-7226-44ac-96cd-a919bda4de4f/05285fee-0562-4394-b320-9f91dfe18c60.gltf",
        sku: "150014",
        image: 'lazar_product_list/Garrett/150014.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150018R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/3fd8d090-5d3c-40d4-a1eb-42747166e47e/614bbbab-c63c-48d5-8940-3f0de4f9696f.gltf",
        sku: "150018R",
        image: 'lazar_product_list/Garrett/150018R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150018L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/6ac2453a-6bb3-41b4-9108-1bf010b4286b/22d3938a-6c9f-409a-820b-332541e7bfca.gltf",
        sku: "150018L",
        image: 'lazar_product_list/Garrett/150018L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: true,
      },
      {
        name: 'Garrentt',
        series: "GARRETT 150099",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/e63dd33f-b38a-4aa5-ab03-84d7948d81ff/36f9b3d1-aea0-4b1f-b47e-dcc164f24ee0.gltf",
        sku: "150099",
        image: 'lazar_product_list/Garrett/150099.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
    ],
    bali: [
      {
        name: 'Bali',
        series: "Bali 155064R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/ab1c9695-a345-4a62-ae31-1d5134e6a86b/6efc6719-9782-4ed7-8731-b5bbb3377f0d.gltf",
        sku: "155064R",
        image: 'lazar_product_list/Bali/155064R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        sku: "155064L",
        name: "Bali",
        series: "Bali 155064L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/2de7df87-bc7d-46a6-ba9b-3df63772a124/81a98024-edb8-47bd-92f0-fdd145574126.gltf",
        image: 'lazar_product_list/Bali/155064L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        sku: "155043_R",
        name: "Bali",
        series: "Bali 155043_R",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/ab1da9b5-5f49-4a15-ad00-901ae20dc135/0bc5f50d-5b21-4b34-badc-60507a885f14.gltf",
        image: 'lazar_product_list/Bali/155043_R.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        sku: "155043_L",
        name: "Bali",
        series: "Bali 155043_L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/09c667a3-5b93-46b3-b372-7c87258b0443/7b63cdde-e17a-4026-b0b1-2fb87e57b432.gltf",
        image: 'lazar_product_list/Bali/155043_L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        sku: "1550120 L",
        name: "Bali",
        series: "Bali 1550120 L",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/0c2d136b-faf8-4cc6-9492-eea4ba8ddcd0/b0d4b147-6e2a-48d2-99fc-aabe35220522.gltf",
        image: 'lazar_product_list/Bali/1550120_L.png',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
    ],
    bohemia: [
      {
        name: 'Bohemia',
        series: "Bohemia 507204",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/1b7cabfc-06cd-401b-8e82-c22008c32080/9a347ab5-c151-4a56-918d-95172f96927e.gltf",
        sku: "507204",
        image: 'lazar_product_list/Bohemia/507204.jpg',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        sku: "507213",
        name: "Bohemia",
        series: "Bohemia 507213",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/5dfa6165-6b7e-4c2b-a7a4-a37a2187f3e3/4db3d128-3064-48a3-b7fc-db6c70c8b5fc.gltf",
        image: 'lazar_product_list/Bohemia/507213.jpg',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        sku: "5072070D",
        name: "Bohemia",
        series: "Bohemia 5072070D",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/4913e798-b39d-405b-a04a-363eb78b405d/f2b9151d-cc1e-493b-a9a5-80c49ea439e3.gltf",
        image: 'lazar_product_list/Bohemia/5072070D.jpg',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        sku: "507220",
        name: "Bohemia",
        series: "Bohemia 507220",
        modelName: "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/1fb442a1-f20d-4d11-97f1-59da80b36c11/a1ed59db-4f46-4b6f-bfe9-33c26dfa2a74.gltf",
        image: 'lazar_product_list/Bohemia/507220.jpg',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        "modelName": "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/af2b790a-b0d7-4b4b-bfe7-73b9af30658e/c15b848d-d61a-4830-8e10-f7e16a931609.gltf",
        "sku": "507217L",
        "series": "Bohemia 507217L",
        "name": "Bohemia",
        image: 'lazar_product_list/Bohemia/507217L.jpg',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        "modelName": "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/221909fd-12b5-466c-8488-e2d7cafaf0d0/4c63b076-3a2a-4df1-981d-e83d715cdd57.gltf",
        "sku": "507217R",
        "series": "Bohemia 507217R",
        "name": "Bohemia",
        image: 'lazar_product_list/Bohemia/507217R.jpg',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        "modelName": "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/c5f7e7b2-ac74-4908-ba17-10f51375f8a6/b6524954-3a39-41de-9512-1e532b936820.gltf",
        "sku": "507210L",
        "series": "Bohemia 507210L",
        "name": "Bohemia",
        image: 'lazar_product_list/Bohemia/507210L.jpg',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        "modelName": "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/bbdc3d33-50ee-4a7e-b034-b515b05b04fe/7047b713-b877-47dc-857f-836e03f7bb47.gltf",
        "sku": "507210R",
        "series": "Bohemia 507210R",
        "name": "Bohemia",
        image: 'lazar_product_list/Bohemia/507210R.jpg',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
      {
        "modelName": "https://app.poly9.ai/public/651d2f5646cb5000084cb391/3dProduct/styled-GLTF/87a2f040-c081-4d4b-9482-24cca24b8d81/53831c3e-ac70-4f53-9c4d-0267dc82741d.gltf",
        "sku": "507205",
        "series": "Bohemia 507205",
        "name": "Bohemia",
        image: 'lazar_product_list/Bohemia/507205.jpg',
        description: "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions: "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        price: 4933,
        options: ['Wood'],
        category: "Sofa",
        pillowShow: false,
      },
    ],
    UPHOLSTERY: {
      0: {
        name: "Kent Sofa",
        image: "upholstery/1.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0f4151bd-1123-4ebe-98a6-876c094427c7/9eabbc5e-5765-4287-9993-22ba794341b4.gltf",
      },
      1: {
        name: "Milford Sofa",
        image: "upholstery/2.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/74dae2aa-4927-4850-88af-7000f9cd3e45/d3776256-1113-44c7-b1ff-27c86273914c.gltf",
      },
      2: {
        name: "Sally Chair",
        image: "upholstery/3.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/07b4a55d-e899-4a0a-b4ca-9a599ac990f6/377a63ad-6b07-4aa0-a9f7-9b43425f8f94.gltf",
      },
      3: {
        name: "Leo Ottoman",
        image: "upholstery/4.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7d3d3361-1acd-4c05-bfb4-f6dac15bf034/5fa4ba02-7403-4aac-896e-4890f4952ea6.gltf",
      },
    },
  },
  inspirations1: {
    "caracole": [
      {
        name: "Caracole Arm Chair",
        image: "lazar_product_list/Kinetic_Sofa/364020_SOFA.png",
        modelName: "https://app.poly9.ai/public/6523e1c25c55ac0008276be4/3dProduct/styled-GLTF/d4de77d5-52f5-4b36-af2a-2f0d2ea1a1d0/f84fa8e3-530c-45f6-8842-6cd8ad40be22.gltf",
        sku: "364020",
        category: "Sofa",
        options: ['Wood', 'Metal'],
        price: 5543,
        optionSku: {
          Wood: '364020',
          Metal: '364020C',
        },
      },
    ],
    "passadena": [
      {
        name: "Passadena",
        series: "",
        description: "Platinum Collection",
        dimensions: "94 Width X 34.5 Height X 39.5 Depth, Arm Height 26.5, Seat Height 18, Seat Width 76, Seat Depth 21",
        designedBy: "JOHN MASCHERONI",
        sku: "703420",
        image: "lazar_product_list/Belize_Sofa/703420.png",
        category: "Sofa",
        options: ['Wood'],
        price: 5047,
        pillowShow: true,
        modelName: "https://app.poly9.ai/public/6523e1c25c55ac0008276be4/3dProduct/styled-GLTF/5b957165-d291-4270-a5d6-dfcf37ca0cc1/1333a1be-992c-4ea2-a381-dadfed4464a2.gltf",
      },

    ],
    "slip": [
      {
        name: "Slip Cover Chair",
        series: "JEREMIAH 1416",
        description:
          "1 Arm Chaise (LAF), Armless Sofa, Corner, 1 Arm Sofa (RAF) In Alleghany Platinum With Espresso Finish",
        dimensions:
          "LAF Chaise: 38W, 29H, 68D, 19SH, 25AH Armless Sofa: 72W, 29H, 39D, 19SH Corner: 38W, 29H, 38D, 19SH, RAF Sofa:81W, 29H, 39D, 19SH, 25AH",
        designedBy: "",
        sku: "141614",
        price: 4933,
        image: 'lazar_product_list/Jeremiah/141614.png',
        options: ['Wood'],
        category: "Sofa",
        modelName: "https://app.poly9.ai/public/6523e1c25c55ac0008276be4/3dProduct/styled-GLTF/bac075e2-611d-43ad-9f9a-31bcd3deb843/78bb125b-a028-4261-bbf5-eb73aeba05b6.gltf",
        pillowShow: false,
      },
    ],
    "willowbrook": [
      {
        name: "Willowbrook Chair",
        series: "2818 GROVE SERIES",
        description:
          "Swivel Chair In Bellisimo Grey W/25 Stainless Steel Swivel Base",
        dimensions: "31W, 33H, 33D, 60lbs",
        designedBy: "Michael Wolk",
        sku: "2818SWW",
        image: 'lazar_product_list/Grove/2818SWW.png',
        category: "Swivel",
        options: ['Metal', 'Upholstered'],
        optionSku: {
          Wood: '2818SWW',
          Metal: '2818CSW',
          Upholstered: '2818SWU',
        },
        optionPrice: {
          Wood: 2728,
          Metal: 3024,
          Upholstered: 2467,
        },
        price: 2728,
        modelName: "https://app.poly9.ai/public/6523e1c25c55ac0008276be4/3dProduct/styled-GLTF/296123e9-a3c3-4e9b-8637-4f29f03ccc9c/313e9ef5-d52b-4e6d-8484-a2232142cd27.gltf",
      },

    ],
    "york": [
      {
        name: "York Slope",
        series: "2818 GROVE SERIES",
        description:
          "Swivel Chair In Bellisimo Grey W/25 Stainless Steel Swivel Base",
        dimensions: "31W, 33H, 33D, 60lbs",
        designedBy: "Michael Wolk",
        sku: "323Z23W",
        image: 'lazar_product_list/Zenith/323Z23W.png',
        category: "Swivel",
        options: ['Wood'],
        optionSku: {
          Wood: '323Z23W',
        },
        optionPrice: {
          Wood: 2728,
        },
        price: 2728,
        modelName: "https://app.poly9.ai/public/6523e1c25c55ac0008276be4/3dProduct/styled-GLTF/a9fb24b1-9243-4057-a9d9-c2308ea3ce40/900a397c-9998-498e-a74b-79ecc5e9e5a5.gltf",
      },
    ],
    UPHOLSTERY: {
      0: {
        name: "Kent Sofa",
        image: "upholstery/1.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0f4151bd-1123-4ebe-98a6-876c094427c7/9eabbc5e-5765-4287-9993-22ba794341b4.gltf",
      },
      1: {
        name: "Milford Sofa",
        image: "upholstery/2.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/74dae2aa-4927-4850-88af-7000f9cd3e45/d3776256-1113-44c7-b1ff-27c86273914c.gltf",
      },
      2: {
        name: "Sally Chair",
        image: "upholstery/3.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/07b4a55d-e899-4a0a-b4ca-9a599ac990f6/377a63ad-6b07-4aa0-a9f7-9b43425f8f94.gltf",
      },
      3: {
        name: "Leo Ottoman",
        image: "upholstery/4.png",
        modelName:
          "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7d3d3361-1acd-4c05-bfb4-f6dac15bf034/5fa4ba02-7403-4aac-896e-4890f4952ea6.gltf",
      },
    },
  },
  size: {
    "26X26": {
      name: "26 x 26",
      image: "26x26.png",
    },
    "18X18": {
      name: "18 x 18",
      image: "18x18.png",
    },
    "14X20": {
      name: "14 x 20",
      image: "14x20.png",
    },
  },
  fabric: {
    front_side: {
      name: "Front Side",
      image: "front_side.png",
    },
    back_side: {
      name: "Back Side",
      image: "back_side.png",
    },
  },
  border: {
    flange1: {
      name: 'Flange 1"',
      image: "flange1.png",
    },
    flange2: {
      name: 'Flange 2"',
      image: "flange2.png",
    },
    welt: {
      name: "Welt",
      image: "welt.png",
    },
  },
  trim: {
    center: {
      name: "Center",
      image: "center.png",
    },
    vertical: {
      name: "Vertical",
      image: "vertical.png",
    },
  },
  Handle: {
    Black_Walnut_Wood_And_Gold_Ends_TKnob: {
      name: "Black Walnut Wood And Gold Ends TKnob",
      sortName: 'Wood T-Knob',
      image: "handle_thumb/Black_Walnut_Wood_And_Gold_Ends_TKnob.jpg",
    },
    Large_Brushed_Brass_Greek_Key_Pull: {
      name: "Large Brushed Brass Greek Key Pull",
      sortName: 'Large Greek Pull',
      image: "handle_thumb/Large_Brushed_Brass_Greek_Key_Pull.jpg",
    },
    Brass_Open_Circle_Knob: {
      name: "Brass Open Circle Knob",
      sortName: 'Circle Knob',
      image: "handle_thumb/Brass_Open_Circle_Knob.jpg",
    },
    Small_Gold_Deco_Drawer_Pull: {
      name: "Small Gold Deco Drawer Pull",
      sortName: 'Small Deco Pull ',
      image: "handle_thumb/Small_Gold_Deco_Drawer_Pull.jpg",
    },
    Elegant_Polished_Nickel_Pull: {
      name: "Elegant Polished Nickel Pull",
      sortName: 'Nickle Pull',
      image: "handle_thumb/Elegant_Polished_Nickel_Pull.jpg",
    },
    T_Pull_Knob: {
      name: "T Pull Knob",
      sortName: 'T-Knob',
      image: "handle_thumb/T_Pull_Knob.jpg",
    },
    // Antique_Handle: {
    //   name: "Antique Handle",
    //   sortName: 'Antique Handle',
    //   image: "handle_thumb/Antique_Handle.jpg",
    // },
    'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
      name: "Large Black Walnut Wood & Gold Drawer Handle",
      sortName: 'Dark Wood Pull ',
      image: "handle_thumb/Large_Black_Walnut_Wood_Gold_Drawer_Handle.jpg",
    },
    'Large_Acrylic_&_Gold_Handle_Pull': {
      name: "Large Acrylic & Gold Handle Pull",
      sortName: 'Arcylic Pull',
      image: "handle_thumb/Large_Acrylic_&_Gold_Handle_Pull.jpg",
    },
    Handles_Cabinet_Hardware: {
      name: "Handles Cabinet Hardware",
      sortName: 'Leather Pull',
      image: "handle_thumb/Handles_Cabinet_Hardware.jpg",
    },
    Furniture_Door_Knockers: {
      name: "Furniture Door Knockers",
      sortName: 'Square Pull',
      image: "handle_thumb/Furniture_Door_Knockers.jpg",
    },
    Cabinet_Door_Knob_Hardware: {
      name: "Cabinet Door Knob Hardware",
      sortName: 'Quartrefoil Knob',
      image: "handle_thumb/Cabinet_Door_Knob_Hardware.jpg",
    },
    Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
      name: "Large Light Beech Wood and Gold Drawer Handle",
      sortName: 'Wood Pull',
      image: "handle_thumb/Large_Light_Beech_Wood_and_Gold_Drawer_Handle.jpg",
    },
    Large_Gold_Deco_Drawer_Pull: {
      name: "Large Gold Deco Drawer Pull",
      sortName: 'Quartrefoil Knob',
      image: "handle_thumb/Large_Gold_Deco_Drawer_Pull.jpg",
    },
    Small_Brushed_Brass_Greek_Key_Pull: {
      name: "Small Brushed Brass Greek Key Pull",
      sortName: 'Large Greek Pull',
      image: "handle_thumb/Small_Brushed_Brass_Greek_Key_Pull.jpg",
    },
    Domed_Cut_Glass_Knob: {
      name: "Domed Cut Glass Knob",
      sortName: 'Glass Knob',
      image: "handle_thumb/Domed_Cut_Glass_Knob.jpg",
    },
    Small_White_Beech_Wood_With_Gold_Ends_Handle: {
      name: "Small White Beech Wood With Gold Ends Handle",
      sortName: 'Wood & Gold Pull',
      image: "handle_thumb/Small_White_Beech_Wood_With_Gold_Ends_Handle.jpg",
    },
    Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
      name: "Small Black Walnut Wood with Gold Ends Handle",
      sortName: 'Dark Wood  & Gold yPull',
      image: "handle_thumb/Small_Black_Walnut_Wood_with_Gold_Ends_Handle.jpg",
    },
  },
  THREE_D_MODELS: {
    VINTAGE_FURNITURE: {
      Vintage_Dresser: {
        id: "13290jsdfd923123",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/67cadcdf-cf3f-4817-8ca2-ab2965c0dac2/ff73f089-b79e-4d39-be60-3644f7f2dfb1.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/cb8556c4-06f7-4b7f-ab9c-01d790b58168/48ec4f74-54f9-4745-8bce-1161df9a7c01.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f87fc6b9-6868-478a-8465-7010e7d29acd/6d8056b5-62cd-4498-84e1-3880bd39bafb.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8b1a8a5a-6ce6-42ff-bd12-f5a4410fa072/2dcab614-a3c8-4bdd-becc-9ac8cb106074.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/42460bb5-c7e0-4ae7-9d90-5d119144097b/1f1b4520-0d29-47e6-8099-91bf1cfdf3ec.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/44d6e4e9-b8af-4253-a1d4-29c3098648b8/247fcd18-4331-4ef1-b62f-f80b199309bf.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/84e3fde2-f552-46f2-b34c-d8c94c703a3b/610cda8c-19c7-4c63-9937-87027f78b83d.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/12bb6bdf-1955-49da-be68-dea19f31bc8e/1234ec12-8f1e-4903-b0e7-7cd551967585.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/23ed0356-93ce-49b7-8416-5a883829df50/81ce706b-ca22-4c9b-927c-bc66dd1abce3.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f41fd9cd-af6a-4ff1-8db5-2ef44c941a6e/18f4d6f8-9034-4483-82c9-6ba7ff9183aa.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/332920aa-27f9-4f98-bb80-a7bc4c79f1f5/a82b827a-8bc2-499e-9762-6e7f3aac8ccb.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ee7c3f16-3f09-499f-87cd-c3d4f28c8b82/83f1613d-bef7-4f9f-b529-4aaecbb844fb.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/16f03cc4-10fa-46cc-9cac-3628fddc9c4d/0c6682bb-cbf1-4aca-b6f8-86edf3a4cee8.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d615ad87-fdda-4bee-82ff-8cd689115471/7ad8b148-ff82-4b0b-a7dd-0dda721dc7a4.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/39029a5e-76c2-4626-9e1d-1dde43cabaaa/cf2e90af-36e8-43bd-b93e-65e64025db9a.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7c6613ba-c852-44a2-b18d-6e40a61073b1/a98e15fd-4cbd-4c76-8faf-5cfcbc4532a4.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/07ef5777-3490-4580-972f-77509b542317/156e7e5a-3aaf-450c-bf73-c93ec68794aa.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/89a19747-2469-4ee2-b6a1-15628202cb9f/f2396de3-8747-47a4-a54e-4b549dbb94b1.gltf"
        },
      },
      Tall_Vintage_Dresser: {
        id: "13290jsdfd923123",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/82c3d2f4-e669-4d51-8009-2e5d364ae145/9492b831-fe0a-4e41-a58a-861e795312d1.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/47c8d10e-599d-48be-a715-0ebf35517f0d/9c4acb24-08fb-43df-a40f-200034509c31.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/02baae4c-c32e-4437-a28b-a748f18b5480/c2d783c5-322a-4909-9213-09887fcf714d.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/cc8304fc-7f7a-4d95-b22a-9cf6b9f982fe/3d91bd5f-584a-4720-b69c-6396e61d5e43.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3506f0eb-91f6-4363-a5d7-8ba97a9e01f1/cfe90273-ac48-49e7-a8e4-d62c7f2da8dd.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e5db9a47-1c53-494e-9296-3cce7e16361d/02ef6fca-37fe-4e3d-8443-d76502427d38.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f9c509a0-bce6-4699-97e3-ecb8ac7d80eb/55123db2-18a5-4720-bd6c-39ff3caa8101.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/f0c0920c-918a-483c-96fc-edc19f9335c4/74f64c79-1cc0-4044-a46e-94aadd072efe.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/776bf88b-275d-4ff1-b608-bd6056d5d602/ff8bc96c-41fc-4ae8-8afd-1d7129ddf9d1.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/44b05068-f163-4749-af8a-d47b87c8753a/f73312c7-fa0a-4b15-b419-040e8b384f10.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/fc50f59b-c1bd-479f-b900-7db9eab382f1/190fbee8-3554-4233-8548-66887d71d606.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/82d52fd5-93c5-471b-8a79-88686bcf3e73/83cc936d-ca1e-41db-9728-e0acb166ca28.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b562cdfc-4b2a-490c-83b3-b582032429a0/b7e04269-da05-4de7-8a1b-b0ae7ed68baa.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8dbb6c11-bd47-4089-8663-d77a3f66d2e1/9dc9d068-dc1d-411e-99f1-5aaae32a1947.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/60eb496c-2d82-4d51-a832-3fb71213d3a8/a3dd4763-5d0f-4bbf-8adb-4fac9f535959.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3ec9598c-f10b-40a2-bcd7-fdc83160a56d/e799ee41-6c73-41ee-b5d5-3bafae6a842a.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c1ff4f8b-4ee7-43a0-8f8e-0cc42b034d97/bb66977b-e937-4632-a82a-cd0def09b41b.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/41a5fb63-dc1a-4d6b-a76e-548936d6eb70/63baae70-fab7-4e48-bda7-7b972583d56f.gltf"
        },
      },
      Vintage_Buffet: {
        id: "13290jsdfd923123",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/84c42504-c78c-47d2-b351-d00e6e9057d4/6b9bc8fa-c952-42e8-a003-602146a7fc08.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/2e6ce5ff-ca05-4fad-9773-47078f105ae3/2bc23197-6249-438b-b25a-caa0271e0ad4.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/04545fbe-afa2-49d2-9fae-4fe08625f2a0/8574a83e-75b1-4bdf-8b01-0f40373c7a50.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3eaa5ba4-4875-4ff5-b77b-df9c93d96784/fb215cef-d1f3-4310-9be9-4530d3b31f7c.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a75ffc7d-f88e-4f74-a267-f4025df1b975/62041172-3706-4f8c-9582-8a75a01c85dc.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e275aa00-244d-4af8-ae26-e99008699693/92df7dc6-2180-4d81-8571-c698a04d2378.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/2232143b-3303-4246-94b2-24f60118760a/587b6dd3-f1c1-42ad-b109-62182dece7bf.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6cb02229-b60e-4411-adca-a7704e29d515/8cf5b936-c2d9-4e97-a636-5acfc5fb3de9.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/cb80671f-441f-461f-a9ba-3a27b089356c/b114a93c-8b98-458e-b9ee-0d28b39627da.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/bbeabc02-68cc-4220-a77b-bb5895efc1a8/4d67210b-de6b-47e8-be5b-c70c02006954.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a37a9d3e-8b0a-417a-96dc-5db56eee0ded/c19c73ff-f423-41b2-bd7c-d6d62c76ca40.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/2d729c79-6c17-48e0-aeed-6b7d30d0f525/27d5337e-9cf7-4788-b470-21f55d60d94e.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c4a4ba85-a7ec-43e3-ace5-3747b9f5f125/9170e74d-367f-4cd1-8ac8-e638aa649dc5.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d3ab0c56-a045-4193-8296-aa16d5fbc3a1/1c597702-3de0-463a-9911-555185eeae35.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/52e58516-6c41-482d-b331-60bb25521a37/b8922c5c-ed66-4d34-8b48-1b5dfcba7f18.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/bd64195b-d759-4d5e-857a-6cd644adf292/b3f2a2be-c43c-40b0-a6d9-533ecd24c299.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0a22849c-47ba-424b-beca-7fd09f78b293/ab0b7fe4-a527-4598-8c70-fce9385ee6e4.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/599601c4-59ad-4d7e-aec1-927da539156e/417f6bd3-25f7-4cf9-9dad-fa128dee8be8.gltf"
        },
      },
      Long_Vintage_Credenza: {
        id: "Default",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6a47296e-9395-42c3-bf18-bb34be4b599d/0bf2fc66-66d4-4db7-8c36-9dc1c94bc75b.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/a04952e3-6a83-4939-a8e9-75922eee45a6/574230b0-253f-4639-b3d8-56f8a97d98a7.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/09edde78-e55f-4021-885e-da8e492e2abe/df7be639-3e76-4a70-9ef6-88b093dd8c6d.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3033e44a-696b-4d77-99a7-2b13560aaeca/b2877f47-eb39-47e1-bf10-b74d58138786.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/bc87ff26-7d2a-4731-a4c1-516fe339afb4/6185226f-14a0-4da8-8b61-d9c3c542067d.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7c615316-8fb7-40e1-bc50-6ee8b51864bd/c57cd7ea-f76a-4d57-8509-c35a552db2d0.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/32fd5f45-f5ad-4858-ad4d-eee9c1fcb0f9/3a08e095-cb90-480a-a692-22be8fcf6158.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/8a01de8c-4320-4061-82c5-1b9f31b35741/78d54cd8-2b9c-4004-a474-9245a00ca872.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0a17bee3-511a-495b-8581-d727ccfb2647/4e6aac44-8bdc-4d16-bd23-34f0dd2a68fb.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/83332827-7215-46d5-83ea-53e227578df4/586c21c8-1f8a-4a12-a0c1-dc9db44ce676.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d681a760-195e-4e95-a444-d12268b3d30e/22761dc9-ee84-4433-a7d9-74de22229e15.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ca4bac61-9253-4a3f-87b8-2f96d43c52c6/e12f8684-8ed0-4765-9ada-871eee9ad4b7.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/64ad01cb-97cc-44af-a202-f7d411b3d3c0/5f57537a-714d-4bad-a5b8-aeb1747fba07.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/69ce7ded-997d-440a-8e29-d05f82faed80/a6a24788-1c42-4d98-95de-b329cb7b5837.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6552e56f-2d29-48e6-a8f6-613192c0864f/81f95aeb-e7c0-41c5-b73e-323022aebdeb.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c6725b7e-e36c-4ef3-ab0b-13c736d17264/23feac3e-7eca-44a7-9a43-4935c1b4b08c.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/870fec42-d0b4-446f-85b5-856f22d0e57f/21ea9f89-51e2-48ed-a91e-25532a914368.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/162f66fa-daf3-45f3-bcec-205e3c7741f9/dde08cc6-d41f-4d8a-8b88-0957b9826d1f.gltf"
        },
      },
      Vintage_Nightstand: {
        id: "Default",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/de91c2a2-a0d6-4288-8b59-3e1ebb84ee45/f6570c95-744f-4763-b66f-4be0bea5ab03.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7ae6b805-747a-4d60-b813-63a990c015cb/4122b933-9640-406e-a8b9-11ac366d18d9.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/57c5fa7f-0d14-473d-b484-896d90b7d4f9/8b2bb45c-a18d-4113-bcba-41d52c173bc4.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/40df853d-be3c-484d-b642-87dbd991dd15/04e2c0fc-3ea6-44f2-8109-51a308f048a6.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/2f0eee31-69c1-4af9-97e2-7d5f15d8a891/6d643d41-e49e-48c3-a865-64034048f633.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/52f158ac-ca5a-45d8-95b0-80d96c8e3132/ec22b522-ec34-4a03-a2f0-e8ad591388b5.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/5d1dfc2b-de26-4d2f-bbd4-a1979c28dba9/71a9cc20-e2dd-4111-ae89-1d1027f6808c.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/84f08cdb-5917-4e48-8e44-179ee0c17e6a/dabae027-4468-48b5-91a3-fcf2f0f03227.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/386ee966-7bde-4d12-972a-50fd488beb80/2b7e5121-02b0-4b09-a74b-90c805ce1a72.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/3c39714d-9b62-4e8d-aacc-dfa1c3d6ff3d/3c3db2f8-ec5f-4ec2-8af2-bb949d26fd5a.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d456e1c7-851d-443c-b3a0-985d88136a6e/919fb8fc-e54b-4e95-bf59-a55f65fa8c24.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ceaacf55-aa74-4efe-ad7a-8161a4efd77c/848fd75e-653f-40de-afc8-81eea26e8375.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/dea65350-bb0b-4fce-9d64-b805a71f54ae/54c7c411-ff3b-4193-b358-f5cedb0279ff.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/b0a7dd46-bd07-412b-b398-3d4d47422f2e/50c22627-a2ea-42b3-9d1c-8a0d27d8ac36.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/46d8e0d3-214c-433e-bb2e-9e889d25ecdb/264cfeca-5b98-46c4-9a71-648fa72abec5.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c1900e13-05f1-4307-a16a-74830fccf250/a45d3e6d-817b-465a-b2f2-c55b637cc1ca.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/9a73c53d-deb0-4245-91ca-3c52694dfd10/5043a827-3180-43e8-8174-415ec0f620c9.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/47469c9b-9f8e-4a57-8a28-6cf020b19290/25041ba1-c8f4-4bc3-9068-f634cf6b8f46.gltf"
        },
      },
      Vintage_Detailed_Dresser: {
        id: "Default",
        Black_Walnut_Wood_And_Gold_Ends_TKnob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/82c6f554-034b-41aa-9350-e1c3a7811b94/0a425b3a-9198-46c3-8883-45a6059d3f24.gltf",
        },
        Large_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/45d29224-803f-4939-a5f0-5de8bf62f7af/2f315e01-0b70-4071-af3d-fefd32b4e82f.gltf",
        },
        Brass_Open_Circle_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/63e01844-0873-4847-ab6f-4d21c3a418b0/c8238820-ad43-4d19-bbbd-3c42a51ce88d.gltf",
        },
        Small_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c178c396-f21f-4de5-9907-d8c9dc8b110c/c47185a2-148f-49d4-ac60-9e3cac455f36.gltf",
        },
        Elegant_Polished_Nickel_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ffced5b7-20f8-4bd4-af24-b91462838ec7/1fc06ab5-2f2a-4524-82ad-0a553c4f98d5.gltf",
        },
        T_Pull_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ee2bafdb-289b-41d9-8101-057f54f8575c/fd250356-cfab-4489-b863-1f277506f2c7.gltf",
        },
        Antique_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/9dcc5b36-ab41-47da-8deb-d8b03057477d/c76b1c49-8a01-4688-9630-745be8773dcc.gltf",
        },
        'Large_Black_Walnut_Wood_&_Gold_Drawer_Handle': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/35c00ce0-8c8f-4ae5-ae9c-2b16e4031d85/3b67d10b-389c-4309-9533-d545d9086056.gltf",
        },
        'Large_Acrylic_&_Gold_Handle_Pull': {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c3cefb2a-5133-4e9d-87b5-ca323303f223/bdc89d77-78aa-4a95-8d24-533b10b8713d.gltf",
        },
        Handles_Cabinet_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ac5292a2-bc20-4735-b760-acb7f26daa7f/593e9c9f-926f-44f1-a281-4dad33dd970f.gltf",
        },
        Furniture_Door_Knockers: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/0e59e32e-c9ce-41d4-a341-dc28a6300cc8/d2c41a05-0229-484b-bbe5-449ac5d1f756.gltf",
        },
        Cabinet_Door_Knob_Hardware: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/26d068da-e3b1-4ef2-a948-5efd1d2a2453/ba1ce443-7a1e-485e-93ce-676f38273a8d.gltf",
        },
        Large_Light_Beech_Wood_and_Gold_Drawer_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/ed250cbd-bc06-4677-9221-9c27f7a07bd6/2111fbbf-24d2-404d-9bad-eeb3ba82300f.gltf"
        },
        Large_Gold_Deco_Drawer_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/d7c62ef8-64c1-488b-977b-6d0f844037de/7bc6c961-5893-4e04-b191-e883c350636b.gltf"
        },
        Small_Brushed_Brass_Greek_Key_Pull: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/4866e6a9-0035-4926-923b-cf53ee03fa70/11f205a3-1966-439b-8d2f-0dcaf55943fd.gltf"
        },
        Domed_Cut_Glass_Knob: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/c78d45e0-22fc-4b26-b71a-8bc479625579/10f5d881-c37c-47d8-8c49-fc8bb78fdf90.gltf"
        },
        Small_White_Beech_Wood_With_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7383f291-1bdb-47ee-91b2-9fb7f49644f2/32dbc369-a071-477e-aa2b-bbe6412a6448.gltf"
        },
        Small_Black_Walnut_Wood_with_Gold_Ends_Handle: {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/deddb2ae-e5af-403b-a9b1-1c532b7cbdd6/e8386cd0-8cb7-422c-8b7c-6aa4c30cf79f.gltf"
        },
      },
    },
    PILLOW: {
      size: {
        "26x26": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "24x24": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "22x22": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "20x20": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "18x18": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
          welt: {
            id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/6f2cc826-a3e0-4a05-98e8-0de944f42c22/72ee6f84-56b9-4136-b60f-4616487d06ab.gltf",
          },
        },
        "16x16": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/e3a7f39c-5251-46fe-8b51-fad2851d07d5/b2c9f47e-37a6-46d3-bf37-b7a47b8365d8.gltf",
        },
        "14x22": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7d98c5f3-059e-4fd0-acaa-64bec4114b36/b60f70fb-419e-4c5d-8116-5881ddd86dcb.gltf",
          weld: {
            id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/5490ab9c-22c0-403b-984b-ccd046dcfcdb/c39d66bf-d440-4e8c-8b54-61e3edd4d502.gltf",
          },
        },
        "12x20": {
          id: "https://app.poly9.ai/public/636e083bd5c1580008c1d3fa/3dProduct/styled-GLTF/7d98c5f3-059e-4fd0-acaa-64bec4114b36/b60f70fb-419e-4c5d-8116-5881ddd86dcb.gltf",
        },
      },
    },
  },
};

export const memoryForm = {
  'Chaise': {
    optionCode: "OPT 42-2",
    cost: 486,
  },
  'Loveseat': {
    optionCode: "OPT 42-2",
    cost: 486,
  },
  'Sofa': {
    optionCode: "OPT 42-2",
    cost: 486,
  },
  'Seats - Ottoman': {
    optionCode: "OPT 42-1",
    cost: 339,
  },
  'Swivel': {
    optionCode: "OPT 42-1",
    cost: 339,
  },
  'Chair': {
    optionCode: "OPT 42-1",
    cost: 339,
  },
  'Wedge': {
    optionCode: "OPT 42-1",
    cost: 339,
  },
}

export default content;
