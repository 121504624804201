import React from 'react';
import content from "../../../content.js";
import { checkOriginCondi } from '../../../lib/constant.js';

const Categories = ({ setCategory, category, loader }) => {
    const categoryUpdate = category ? category : checkOriginCondi ? "caracole" : "kinetic"
    const allData = checkOriginCondi ? content?.StoutCategory : content?.Category
    return (
        <div className={`${checkOriginCondi ? 'justify-content-center' : 'justify-content-start'} d-flex w-100 overflow-auto`}>
            <div className="d-flex align-items-center justify-content-center ">
                {allData?.map(data => {
                    return <div
                        key={data?.name}
                        className={`box mx-3 cursor-pointer text-center bg-white category-image ${data?.router === categoryUpdate ? 'active' : ''}`}
                        onClick={() => !loader && setCategory(data?.router)}
                    >
                        <img
                            src={data?.image}
                            alt={data?.name}
                            title={data?.name}
                            style={{ height: 150, width: 150 }}
                        />
                        <span className="badge" style={{ opacity: "0.9" }}>
                            {data?.name}
                        </span>
                    </div>
                })}
            </div>
        </div>
    );
};

export default Categories;