import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import { loaderAtom, modelAtom, upholsteryAtom, upholsteryItemAtom } from '../../../state'
import { GET_PRODUCT_SUMMARY } from '../graphQl/Query'
import ShareUpholsteryModelViewer from '../../../components/GoogleModelViewer/ShareUpholsteryModelViewer'
import AntdSpin from '../../../components/Loader/AntdSpin'
import { ImageGlobalUrl } from '../../../lib/constant'
import { memoryForm } from '../../../content'

const SharePage = () => {
  // get id from url
  const { id } = useParams()
  // state define
  const [shareData, setShareData] = useState()
  const [selectedProduct, setSelectedProduct] = useState()
  const [selectedProductIndex, setSelectedProductIndex] = useState(0)
  const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
  const [upholsteryItem, setUpholsteryItem] = useRecoilState(upholsteryItemAtom)
  const [upholsteryAtomState, setUpholsteryAtomState] = useRecoilState(upholsteryAtom);
  const [loader, setLoader] = useRecoilState(loaderAtom)
  // Query 
  const [getSummary] = useLazyQuery(GET_PRODUCT_SUMMARY)

  // when id is change call getPoductSummary query
  useEffect(() => {
    if (id) {
      setLoader(true)
      const summaryFun = async () => {
        const { data } = await getSummary({
          variables: {
            uniqueCode: id
          }
        })
        const selectedRecord = data?.getProductSummaryByUniCode?.productSummary?.[0]
        setShareData(data?.getProductSummaryByUniCode)
        selectProductCustomize(selectedRecord)
        setLoader(false)
      }
      summaryFun()
    }
  }, [id])

  const selectProductCustomize = (data) => {
    if (data) {

      const { upholsteryItem, fabric, modelAtomValue } = data;
      setSelectedProduct(data)
      setModelAtomValue(modelAtomValue)
      setUpholsteryItem(upholsteryItem)
      setUpholsteryAtomState(fabric)
    }
  }

  const upholsteryRender = () => {
    if (selectedProduct) {
      const { platinumSeat } = selectedProduct?.upholsteryItem
      const quen = selectedProduct?.quantity
      const memoryName = memoryForm[selectedProduct?.fabric?.category]
      const priceSum = quen * (selectedProduct?.fabric?.price + (memoryName?.cost || 0))
      const skuName = selectedProduct?.fabric?.optionSku?.[selectedProduct?.legSelection] || selectedProduct?.fabric?.sku
      return (
        <div className='w-100'>
          <p> {selectedProduct?.name}</p>
          <p> $ {priceSum?.toLocaleString()}</p>
          {skuName && <>
            <div className='border-gray my-2'></div>
            <p className="m-0 color-gray">SKU: {skuName}</p>
          </>
          }
          {selectedProduct?.paintName?.Fabric && <>
            <div className='border-gray my-2'></div>
            <p className="m-0 color-gray">Fabric: {selectedProduct?.paintName?.Fabric}</p>
          </>
          }
          {selectedProduct?.paintName?.Inner && <>
            <div className='border-gray my-2'></div>
            <p className="m-0 color-gray">Inside Fabric: {selectedProduct?.paintName?.Inner}</p>
          </>
          }
          {selectedProduct?.paintName?.Outer && <>
            <div className='border-gray my-2'></div>
            <p className="m-0 color-gray">Outside Fabric: {selectedProduct?.paintName?.Outer}</p>
          </>
          }
          {selectedProduct?.paintName?.Pillow && <>
            <div className='border-gray my-2'></div>
            <p className="m-0 color-gray">Pillow: {selectedProduct?.paintName?.Pillow}</p>
          </>
          }
          {selectedProduct?.paintName?.Legs && <>
            <div className='border-gray my-2'></div>
            <p className="m-0 color-gray">Legs ({selectedProduct?.legSelection}): {selectedProduct?.paintName?.Legs}</p>
          </>
          }
          <div className='border-gray my-2'></div>
          <p className="m-0 color-gray">Memory Form: {platinumSeat === "Yes" ? memoryName?.optionCode : 'Standard'}</p>
        </div>
      )
    }
  }

  return (
    <>
      <Header />
      {loader && !shareData?.productSummary && <AntdSpin />}
      <div className="container-fluid summary-header-card pt-4 pb-5">
        <div>
          <h5 className="title text-center">PROJECT SUMMARY</h5>
          <h6 className="sub-title mt-4 text-center">{shareData?.name}</h6>
          <p className="sub-title text-center color-gray">{shareData?.email}</p>
          <div className='row justify-content-center'>
            {shareData?.productSummary?.map((data, index) => {
              const selected = selectedProductIndex === index
              return (
                <div
                  key={`${data?.name} ${index}`}
                  className={`card mt-4 cursor-pointer p-0 me-md-4 mb-lg-0 mb-4 align-items-center ${selected ? 'active-category' : 'border-gray'}`}
                  style={{ width: '18rem' }}
                  onClick={() => {
                    setSelectedProductIndex(index)
                    selectProductCustomize(data)
                    setLoader(true)
                  }}>
                  <div className='d-flex justify-content-center align-items-center' style={{ width: 200, height: 200 }} >
                    <img className="card-img-top" src={`${ImageGlobalUrl}/${data?.base64}`} alt="Card image cap" />
                  </div>
                  <div className="card-body text-center border-top-gray w-100" >
                    <p className={`${selected ? 'text-black' : 'color-gray'} card-text  `}>
                      {data?.seriesName} {data?.name}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="product-info-row ">
        <div className="row ">
          <div className="col-lg-7 col-xl-7 left-part mt-rem-6 mt-0 d-flex justify-content-between flex-column " style={{ height: '57vh' }}>
            <ShareUpholsteryModelViewer selectedProduct={selectedProduct} />
          </div>
          <div className="col-lg-5 col-xl-5 right-part text-left mobile-shadow mt-4">
            <div className="d-flex align-items-stretch tab-item-wrapper">
              {upholsteryRender()}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SharePage
// import React from 'react'

// const index = () => {
//   return (
//     <div>share page</div>
//   )
// }

// export default index