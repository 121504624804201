import { Drawer, InputNumber, Space } from 'antd'
import React, { useState, createRef, useEffect } from 'react'
import { Button, Col, Form, Row } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from 'recoil'
import { addFabricBeforeAtom, addFabricToggleAtom, addNewCustomFabricAtom, aspectRationAtom, beforeSelectedImageScaletom, boxDimentionAtom, cropperSelectionAtom, currentCustomizationAtom, selectedCustomFabricAtom, selectedImageScaletom, showModalAtom, uploadImageAtom } from '../../state'
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import { DebounceInput } from 'react-debounce-input';
import { v4 as uuidv4 } from 'uuid';

const CropImage = () => {
    const cropperImageRef = createRef();

    const addFabricBefore = useRecoilValue(addFabricBeforeAtom);
    const [showModal, setShowModal] = useRecoilState(showModalAtom)
    const [inputValue, setInputValue] = useRecoilState(selectedImageScaletom);
    const [inputSetValue, setInputSetValue] = useRecoilState(beforeSelectedImageScaletom);
    const [boxDimentions, setBoxDimentions] = useRecoilState(boxDimentionAtom)
    const currentCustomization = useRecoilValue(currentCustomizationAtom);
    const [uploadImage, setUploadImage] = useRecoilState(uploadImageAtom)
    const [addFabricToggle, setAddFabricToggle] = useRecoilState(addFabricToggleAtom)
    const [validation, setValidation] = useState([])
    const [aspectRation, setAspectRation] = useRecoilState(aspectRationAtom)
    const [cropperData, setCropperData] = useRecoilState(cropperSelectionAtom)
    const [addNewCustomFabric, setAddNewCustomFabric] = useRecoilState(addNewCustomFabricAtom)
    const [selectedCustomFabric, setSelectedCustomFabricAtom] = useRecoilState(selectedCustomFabricAtom)

    useEffect(() => {
        setTimeout(() => {
            const cropper = cropperImageRef?.current?.cropper;
            if (cropperData && cropperData.height && cropperData.width && cropper) {
                cropper.setData(cropperData)
            }
        }, 1000)
    }, [])

    const onChange = (newValue, key) => {
        const changeData = {
            ...inputSetValue,
            [key]: newValue
        }
        setInputSetValue(changeData);
        if (changeData?.Horizontal && changeData?.Vertical) {
            let rationInput = {
                ...aspectRation,
            }
            const ratio = changeData?.Horizontal / changeData?.Vertical
            const cropper = cropperImageRef?.current?.cropper;
            if (cropper) {
                const selectionCropperData = cropper.getData()
                if (aspectRation?.show) {
                    cropper.setAspectRatio(Number(ratio));
                    rationInput = {
                        ...rationInput,
                        height: selectionCropperData?.height?.toFixed(0),
                        width: selectionCropperData?.width?.toFixed(0),
                        ratio: ratio?.toFixed(2)
                    }
                } else {
                    rationInput = {
                        ...rationInput,
                        height: selectionCropperData?.height?.toFixed(0),
                        width: selectionCropperData?.width?.toFixed(0),
                    }
                }
            }
            setAspectRation(rationInput)
        }
        const filerData = validation?.filter(data => data !== key)
        setValidation(filerData)

        setSelectedCustomFabricAtom({
            ...selectedCustomFabric,
            [key]: newValue
        })

    };

    const submitHandler = () => {
        setValidation([])
        let customData = {
            ...selectedCustomFabric,
            finish: addFabricBefore,
            setHorizontal: inputValue?.Horizontal,
            setVertical: inputValue?.Vertical,
            Scale: selectedCustomFabric?.Scale || 1,
            aspectRation,
            cropperData
        }
        if (selectedCustomFabric?.id) {
            const newData = addNewCustomFabric?.map(data => data?.id === selectedCustomFabric?.id ? { ...data, ...customData } : data)
            setInputSetValue({
                ...inputSetValue,
                ...customData
            })
            setSelectedCustomFabricAtom(customData)
            setAddNewCustomFabric(newData)
        } else {
            customData = { ...customData, id: uuidv4() }
            setInputSetValue({
                ...inputSetValue,
                ...customData
            })
            setSelectedCustomFabricAtom(customData)
            setAddNewCustomFabric([...addNewCustomFabric, customData])
        }
        setShowModal(false)
        setAddFabricToggle(true)
        setUploadImage(addFabricBefore)
    }

    const onCrop1 = async () => {
        const verticalVal = document.getElementById('Vertical Size')?.value
        const horizontalVal = document.getElementById('Horizontal Size')?.value
        if (verticalVal && horizontalVal) {
            const cropper = cropperImageRef.current?.cropper;
            const selectionCropperData = cropper.getData()
            const getData = cropper.getCropBoxData()
            const cropperFullImg = document.querySelector('.cropper-preview-image .cropper-canvas img')
            const final_img = cropperFullImg.src
            var image = new Image();
            image.src = final_img;
            await image.decode();
            const { height: img_height, width: img_width } = image;

            const containerHeight = cropperFullImg.offsetHeight
            const containerWidth = cropperFullImg.offsetWidth
            const boxHeight = getData.height.toFixed()
            const boxWidth = getData.width.toFixed()
            setAspectRation(prevAspectRation => ({
                ...prevAspectRation,
                height: selectionCropperData?.height.toFixed(),
                width: selectionCropperData?.width.toFixed(),
            }));
            const calculatedHeight = img_height * boxHeight / containerHeight
            const calculatedWidth = img_width * boxWidth / containerWidth
            setCropperData(selectionCropperData)
            onCrop(calculatedHeight, calculatedWidth)
        }
    };

    const onCrop = async (calculatedHeight, calculatedWidth) => {
        const cropper = cropperImageRef.current?.cropper;
        if (cropper) {
            const verticalVal = document.getElementById('Vertical Size')?.value
            const horizontalVal = document.getElementById('Horizontal Size')?.value
            if (verticalVal && horizontalVal) {
                const cropperFullImg = document.querySelector('.cropper-preview-image .cropper-canvas img')
                const final_img = cropperFullImg.src
                var image = new Image();
                image.src = final_img;
                await image.decode();
                const { height: img_height, width: img_width } = image;
                let vertical_repeat = verticalVal
                let horizontal_repeat = horizontalVal
                let height_inch = (img_height * vertical_repeat) / (calculatedHeight)
                let width_inch = (img_width * horizontal_repeat) / (calculatedWidth)
                const pilloSize = currentCustomization.size.name
                const heightDimention = pilloSize === '12x20' ? 0.45 : 0.85
                const widthDimention = pilloSize === '12x20' ? 0.75 : 0.85
                let t_c_h = height_inch * heightDimention
                let t_c_w = width_inch * widthDimention
                let V = (Number(pilloSize.slice(0, 2)) / t_c_h)
                let U = (20 / t_c_w)
                setBoxDimentions({
                    height: calculatedHeight,
                    width: calculatedWidth
                })
                setInputValue({
                    Horizontal: U,
                    Vertical: V,
                })
            }
        }
    };

    const onRatioChange = (key, value) => {
        const cropper = cropperImageRef?.current?.cropper;
        if (value) {
            let rationInput = {
                [key]: value,
            }
            const ratio = inputSetValue?.Horizontal / inputSetValue?.Vertical
            if (cropper) {
                cropper.setAspectRatio(Number(ratio));
                const selectionCropperData = cropper.getData()
                rationInput = {
                    ...rationInput,
                    height: selectionCropperData?.height?.toFixed(0),
                    width: selectionCropperData?.width?.toFixed(0),
                    ratio: ratio?.toFixed(2)
                }
            }
            setAspectRation(prevAspectRation => ({
                ...prevAspectRation,
                ...rationInput
            }));
        } else {
            cropper.setAspectRatio(null);
            const selectionCropperData = cropper.getData()
            console.log("calledsad 1 s")
            setAspectRation({
                ...aspectRation,
                height: selectionCropperData?.height?.toFixed(0),
                width: selectionCropperData?.width?.toFixed(0),
                [key]: value
            })
        }
    }

    return (

        <Drawer
            title="Step 1: Size of the fabric"
            placement="top"
            closable={false}
            onClose={() => {
                if (selectedCustomFabric?.id) {
                    submitHandler()
                } else {
                    setShowModal(false)
                }
            }}
            open={true}
            key={"placement"}
            className="Customization-drawer"
            extra={
                <Space>
                    <div
                        className="cursor-pointer d-flex justify-content-end"
                        onClick={() => {
                            if (selectedCustomFabric?.id) {
                                submitHandler()
                            } else {
                                setShowModal(false)
                            }
                        }}
                        style={{ color: 'black' }}
                    >
                        X
                    </div>
                </Space>
            }
        >
            <div className="row " style={{ height: 'calc(100vh - 57px)' }}>
                <div className="col-lg-9  col-xl-9 left-part mt-rem-6 mt-0" >
                    <div style={{
                        flexDirection: 'column',
                        height: 'calc(100vh - 74px)'
                    }}>
                        <div id="container"
                            style={{ objectFit: 'contain', justifyContent: 'center' }}
                            className='h-100 w-100'
                        >
                            <div style={{ height: '100%', maxWidth: '100%', marginBottom: 10 }} className="cropper-preview-image">
                                <Cropper
                                    ref={cropperImageRef}
                                    style={{ height: '100%', width: "100%" }}
                                    zoomTo={0}
                                    zoomable={false}
                                    initialAspectRatio={1}
                                    preview=".img-preview"
                                    src={addFabricBefore}
                                    viewMode={1}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={0.5}
                                    checkOrientation={false}
                                    guides={true}
                                    cropend={onCrop1}
                                />
                            </div>
                        </div>
                    </div>
                    <div className=' d-flex mt-3 justify-content-center'>
                    </div>
                </div>
                <div className="col-lg-3 py-3 col-xl-3 right-part text-left mobile-shadow d-flex flex-column justify-content-between"
                    style={{ boxShadow: '-8px 1px 16px 0px #00000029', paddingRight: 25 }}
                >
                    <div>
                        <div>
                            <p className='f-18'>PATTERN SIZE</p>
                            <p className='mb-halfrem color-gray'>Horizontal Size</p>
                            <DebounceInput
                                type="number"
                                className={`search-control maxw-100 w-100 ${validation?.includes('Horizontal') ? 'repeat-validation' : ''}`}
                                placeholder={"Horizontal Size"}
                                id="Horizontal Size"
                                autoComplete="off"
                                value={inputSetValue?.Horizontal}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => {
                                    onChange(Number(e.target.value), 'Horizontal')
                                }}
                            />
                            {validation?.includes('Horizontal') ? <p className='m-0 text-red'>Please Enter Horizontal Size</p> : ''}
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>Vertical Size</p>
                            <DebounceInput
                                type="number"
                                className={`search-control maxw-100 w-100 ${validation?.includes('Vertical') ? 'repeat-validation' : ''}`}
                                placeholder={"Vertical Size"}
                                autoComplete="off"
                                id="Vertical Size"
                                value={inputSetValue?.Vertical}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => onChange(Number(e.target.value), 'Vertical')}
                            />
                            {validation?.includes('Vertical') ? <p className='m-0 text-red'>Please Enter Vertical Repeat Size</p> : ''}
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>Finish Width</p>
                            <DebounceInput
                                type="number"
                                className={`search-control maxw-100 w-100 `}
                                placeholder={"Finish Width"}
                                autoComplete="off"
                                id="Finish Width"
                                value={inputSetValue?.finishWidth}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => onChange(Number(e.target.value), 'finishWidth')}
                            />
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>Pattern No</p>
                            <DebounceInput
                                type="string"
                                className={`search-control maxw-100 w-100 `}
                                placeholder={"Pattern No"}
                                autoComplete="off"
                                value={inputSetValue?.name}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => onChange(e.target.value, 'name')}
                            />
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>URL</p>
                            <DebounceInput
                                type="string"
                                className={`search-control maxw-100 w-100 `}
                                placeholder={"URL"}
                                autoComplete="off"
                                value={inputSetValue?.Vendor}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => onChange(e.target.value, 'Vendor')}
                            />
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>Fabric Price</p>
                            <InputNumber
                                value={inputSetValue?.sellPrice}
                                addonAfter="Per Yard"
                                placeholder={"Fabric Price"}
                                style={{ boxShadow: 'unset' }}
                                className={`search-control maxw-100 w-100 `}
                                onChange={(value) => onChange(Number(value), 'sellPrice')}
                            />
                        </div>
                        <div className='mt-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='m-0 p-0 color-gray'>Aspect Ratio</p>
                                <input type="checkbox" className='m-0 p-0'
                                    style={{ width: 16 }}
                                    checked={aspectRation?.show}
                                    onChange={(e) => onRatioChange('show', e.target.checked)}
                                />
                            </div>
                            <DebounceInput
                                type="number"
                                className={`search-control maxw-100 w-100`}
                                placeholder={"Aspect Ratio"}
                                autoComplete="off"
                                value={aspectRation?.ratio}
                                disabled={!aspectRation?.show}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /^\d*\.?\d{0,2}$/; // Allow only up to 2 decimal places
                                    if (regex.test(inputValue)) {
                                        const cropper = cropperImageRef.current?.cropper;
                                        cropper.setAspectRatio(Number(inputValue));
                                        console.log("called sda 1")
                                        setAspectRation({
                                            ...aspectRation,
                                            ratio: Number(inputValue)
                                        })
                                    }
                                }
                                }
                            />
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>Width</p>
                            <DebounceInput
                                type="number"
                                className={`search-control maxw-100 w-100`}
                                placeholder={"Width"}
                                disabled={aspectRation?.show}
                                autoComplete="off"
                                value={aspectRation?.width}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => {
                                    const cropper = cropperImageRef.current?.cropper;
                                    const newData = { ...cropperData, width: Number(e.target.value) }
                                    cropper.setData(newData)
                                    setCropperData(newData)
                                    console.log("calleds ddad 1")
                                    setAspectRation({
                                        ...aspectRation,
                                        width: Number(e.target.value)
                                    })
                                }}
                            />
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>Height</p>
                            <DebounceInput
                                type="number"
                                className={`search-control maxw-100 w-100`}
                                placeholder={"Height"}
                                disabled={aspectRation?.show}
                                autoComplete="off"
                                value={aspectRation?.height}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => {
                                    const cropper = cropperImageRef.current?.cropper;
                                    const newData = { ...cropperData, height: Number(e.target.value) }
                                    cropper.setData(newData)
                                    setCropperData(newData)
                                    console.log("calledsadd ds 1")
                                    setAspectRation({
                                        ...aspectRation,
                                        height: Number(e.target.value)
                                    })
                                }
                                }
                            />
                        </div>
                    </div>
                    <Form.Group as={Row} className='mt-5'>
                        <Col xs="6">
                            <Button
                                className=" w-100 w-min-126px"
                                size="lg"
                                style={{ backgroundColor: 'black', borderColor: 'black' }}
                                type="submit"
                                onClick={() => {
                                    let validate = []
                                    if (!inputSetValue?.Horizontal) {
                                        validate = [...validate, 'Horizontal']
                                    }
                                    if (!inputSetValue?.Vertical) {
                                        validate = [...validate, 'Vertical']
                                    }
                                    setValidation(validate)
                                    if (validate?.length === 0) {
                                        submitHandler()
                                    }
                                }}>
                                Apply
                            </Button>
                        </Col>
                        <Col xs='6'>
                            <Button className=" w-100 w-min-126px" style={{
                                border: "1px solid black",
                                backgroundColor: "white"
                            }} size="lg" variant="light" type="submit" onClick={() => {
                                if (selectedCustomFabric?.id) {
                                    submitHandler()
                                } else {
                                    setShowModal(false)
                                }
                            }}>
                                Cancel
                            </Button>
                        </Col>
                    </Form.Group>
                </div>
                {/* } */}
            </div>
        </Drawer>
    )
}

export default CropImage