import { useNavigate } from "react-router-dom";
import { checkOriginCondi } from "../../lib/constant";

const Header = () => {
    const history = useNavigate();
    return (
        <div className="navigation-bar">
            <nav className="navbar  bg-white">
                <div className="container text-center">
                    <div className="navbar-brand mx-auto cursor-pointer" onClick={() => history('/')} >
                        <img src={checkOriginCondi ? '/stoutLogo.webp' : '/logo.jpeg'} alt="logo" height={origin ? '50' : '30'} />
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;